import React from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  CircularProgress,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { AnHeaderBar } from "components/an";
import { connect } from "react-redux";
import { DirectoryActions, ConfigurationActions } from "redux/actions";
import SpecialityProfessionalList from "components/SpecialityProfessionalList";

class SpecialityScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.query = new URLSearchParams(this.props.location.search);
  }

  componentDidMount() {
    this.props.getProfessionalToSpeciality(this.query.get("id"));
    this.props.fetchTitles();
  }

    componentDidUpdate(prevProps) {
        if (this.props.removed && (this.props.removed !== prevProps.removed)) {
            this.props.history.push("/dashboard/cartilla");
        }
    }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  deleteSpeciality = () => {
    this.props.deleteSpeciality(this.query.get("id"));
    this.handleClose();
  };
  
  render() {
    const { classes, professionalList, titles } = this.props;
    const specialityName = this.props.match.params.name.charAt(0).toUpperCase() +
      this.props.match.params.name.slice(1).replace("-", " ")
    
      return (
        <div className={classes.root}>
            <div className={this.props.classes.container}>
                <AnHeaderBar />

                {(this.props.loading ? (
                    <Box align="center" p={10}>
                        <CircularProgress 
                            size={40}
                        />
                        
                        <Box mt={3}>  
                            <Typography>
                                Cargando ...
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <Box>
                        <Box mt={2} mb={2}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center">
                                
                                <Grid item>
                                    <Typography component="h3" variant="h4">
                                    {specialityName}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleOpen}
                                    >
                                        Eliminar especialidad
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>

                        {this.props.loading ? (
                            <Grid container direction="row" justify="center">
                                <CircularProgress color="secondary" size={25} />
                            </Grid>
                        ) : (
                            !this.props.removed && (
                                <Box>
                                    {professionalList.length > 0 ? (
                                        <Box>
                                            <SpecialityProfessionalList
                                                data={professionalList}
                                                titles={titles}
                                            />
                                        </Box>
                                    ) : (
                                        <Box bgcolor="#fff">
                                            <Alert severity="info">
                                                Esta especialidad no tiene ningún profesional asignado
                                            </Alert>
                                        </Box>
                                    )}
                                </Box>
                            )
                        )}
                    </Box>
                ))}

                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle id="alert-dialog-title">
                        ¿Está seguro de eliminar esta especialidad?
                    </DialogTitle>
                    
                    <DialogContent>
                        <DialogContentText>
                            <Alert severity="warning">
                                {`Si elimina la especialidad '${specialityName}' también se borrará de los profesionales asignados.`}
                            </Alert>
                        </DialogContentText>
                    </DialogContent>
              
                    <DialogActions>
                        <Button onClick={this.handleClose}>Cancelar</Button>
                        <Button onClick={this.deleteSpeciality} color="primary">
                            Eliminar
                        </Button>
                </DialogActions>
            </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  removed: state.directory.removed,
  loading: state.directory.loading,
  professionalList: state.directory.professionalList,
  titles: state.configuration.titles,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTitles: () => dispatch(ConfigurationActions.fetchTitles()),
  getProfessionalToSpeciality: (id) =>
    dispatch(DirectoryActions.getProfessionalToSpeciality(id)),
  deleteSpeciality: (id) => dispatch(DirectoryActions.deleteSpeciality(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialityScreen);
