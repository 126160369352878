const styles = theme => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    fixedHeight: {
        height: 240,
    },
    cardContainer: {
        marginBottom: theme.spacing(4)
    },
    loadingComp: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        padding: 10,
        justifyContent: 'flex-end'
    }
});


export default styles