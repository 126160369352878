
function handleNavDrawer() {
    return {
        type: 'DASHBOARD_HANDLE_NAV_DRAWER',
    }
}

function handleSideDrawer() {
    return {
        type: 'DASHBOARD_HANDLE_SIDE_DRAWER',
    }
}

function selectItem(selectedItem) {
    return {
        type: 'DASHBOARD_SELECTED_ITEM',
        payload: {
            selectedItem
        }
    }
}

function clearMessage() {
    return {
        type: 'DASHBOARD_CLEAR_MESSAGE',
    }
}

export const DashboardActions = {
    handleNavDrawer,
    handleSideDrawer,
    selectItem,
    clearMessage
}