import React, { PureComponent } from 'react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { DashboardActions, ProfessionalActions, ConfigurationActions } from 'redux/actions'
import ProfessionalProfile from './components/ProfessionalProfile';
import ProfessionalDetails from './components/ProfessionalDetails';
import data from "assets/data/professional.json";
import { AnHeaderBar } from 'components/an';

class ProfessionalScreen extends PureComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            professional: data
        }
  }

  componentDidMount() {
        this.props.fetchSpecialities()
        this.props.fetchProfessional(this.props.match.params.code)
  }

  componentDidUpdate(prevProps) {
        if (this.props.update && (this.props.update !== prevProps.update)) {
            this.props.fetchProfessional(this.props.match.params.code)
        }
  }

  componentWillUnmount() {
        this.props.resetProfessionalState()
  }

  activateProfessional = () => {
        this.props.activateProfessional(this.props.match.params.code, this.props.professional.specialities)
  }

  updateProfessionalSpecialities = () => {
        this.props.updateProfessionalSpecialities(this.props.match.params.code, this.props.professional.specialities)
  }
  
  render() {
    const {
      classes,
      professional,
      titles,
      specialities,
    } = this.props;
    
    return (
      <div className={classes.root}>
            <div className={this.props.classes.container}>
                <AnHeaderBar />
                
                {(this.props.loading || this.props.unlinkProfessionalLoading ? (
                    <Box align="center" p={10}>
                        <CircularProgress  size={40} />
                        
                        <Box mt={3}>
                            <Typography>
                                Cargando ...
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ProfessionalProfile
                                name={professional.name}
                                status={professional.status}
                                webcard={professional.webcard}
                                avatar={this.state.professional.avatar}
                                unlinkProfessional={this.props.unlinkProfessional}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <ProfessionalDetails
                                pendingUser={
                                    professional.status === 1
                                }
                                currentSpecialities={professional.specialities}
                                specialities={specialities}
                                activateProfessional={this.activateProfessional}
                                updateProfessionalSpecialities={this.updateProfessionalSpecialities}
                            />
                        </Grid>
                    </Grid>
                ))}
            </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
    specialities: state.configuration.specialities,
    
    loading: state.professional.loading,
    professional: state.professional.data,
    specialitiesList: state.professional.specialitiesList,
    update: state.professional.update,
    unlinkProfessionalLoading: state.professional.unlink.loading
})

const mapDispatchToProps = dispatch => ({
  fetchSpecialities: () => dispatch(ConfigurationActions.fetchSpecialities()),
  handleSideDrawer: () => dispatch(DashboardActions.handleSideDrawer()),
  
  
    unlinkProfessional: (id) => dispatch(ProfessionalActions.unlinkProfessional(id)),
  fetchProfessional: (id) => dispatch(ProfessionalActions.fetchProfessional(id)),
  activateProfessional: (id, specialitiesList) => dispatch(ProfessionalActions.activateProfessional(id, specialitiesList)),
  updateProfessionalSpecialities: (id, specialitiesList) => dispatch(ProfessionalActions.updateProfessionalSpecialities(id, specialitiesList)),


  resetProfessionalState: () => dispatch(ProfessionalActions.resetProfessionalState()),
})


export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalScreen)

