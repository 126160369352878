import palette from '../palette';
import theme from '../index.js'

import MuiPaper from './MuiPaper';
import MuiButton from './MuiButton';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';
import MuiTableRow from './MuiTableRow';
import MuiTableCell from './MuiTableCell';

export default {
  MuiPaper,
  MuiButton,
  MuiTab,
  MuiTabs,
  MuiDrawer: {
      root: {
          height: '100%',
          float: 'left',
      }
  },
  MuiStepper: {
      root: {
          padding: '15px 10px'
      },
  },
  MuiStepIcon: {
    root: {
        '&.MuiStepIcon-completed': {
            color: 'green',
        }
    }  
    // completed: {
    //       color: 'green'
    // },
  },
  MuiFilledInput: {
      root: {
          backgroundColor: '#f1f1f1',
          borderRadius: 4,
          
          '&$focused': {
              backgroundColor: '#f1f1f1'
          },
          '&$error': {
            border: `1px solid ${palette.error.dark}`
        }
      },
      input: {
        height: 25,
      }
  },
  MuiChip: {
    root:{
      fontSize: 11
    },
  },
  MuiTable: {
    root: {
      borderCollapse: 'separate',
      borderSpacing: '0px 2px'
    },
  },
  MuiTableHead: {
    root: {
        backgroundColor: '#f4f5f9',
    }
  },
  MuiTableRow: {
    root: {
        borderBottom: '1px solid #ddd',

        '&:nth-child(even)': {
            backgroundColor: '#f4f5f9',
        },
        '&:nth-child(odd)': {
            backgroundColor: 'transparent',
        },
    },
    head: {
        backgroundColor: 'transparent' ,
    },
    hover: {
        cursor: 'pointer'
    }
  },
  MUIDataTable: {
      paper: {
        backgroundColor: 'transparent',
      }
  },
  MUIDataTableToolbar: {
      root: {
          padding: 0,
      }
  },
  MUIDataTableBodyCell: {
    root: {
      fontSize: 12
    },
  },
  MuiTableCell: {
    root: {
        borderBottom: '1px solid #ddd',
        padding: 12,

        '&.MuiTableCell-head': {
          backgroundColor: 'transparent',
        },
        
        ['@media (max-width: 959.95px)']: {
            height: '39px !important',
            padding: 0,
            fontSize: '12px !important',
            lineHeight: '29px',
            padding: '5px 10px'
        }
    },
  },

  MUIDataTablePagination: {
    root: {
      '&:last-child': {
        padding: 0,
        backgroundColor: '#f4f5f9' ,
      }
    },
    toolbar: {
      padding: 0,
    }
  },
//   MuiTableRow,
//   MuiTableCell,
};
