const defaultState = {
    loading: false,
    authRequestId: null,
    webviewURL: null,
    webviewTitle: '',
    webviewSubtitle: '',
    authStatus: null,
    gateway: 0,
    activeAccountGateway: null,
    loadingGateway: false,
    delete: false,
    success: false
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {

        /**
         * Get gw
         */

        case 'PAYMENT_GET_AVAILABLE_GATEWAY_START':
            return Object.assign({}, state, {
                loadingGateway: true,
            })

        case 'PAYMENT_GET_AVAILABLE_GATEWAY_SUCCESS':
            return Object.assign({}, state, {
                loadingGateway: false,
                gateway: action.payload.gateway,
                activeAccountGateway: action.payload.activeAccountGateway,
                delete: false,
                success: false
            })

        case 'PAYMENT_GET_AVAILABLE_GATEWAY_FAILURE':
            return Object.assign({}, state, {
                loadingGateway: false,
            })

        /**
         * Init gw config
         */
        case 'PAYMENT_GATEWAYS_INIT_START':
            return Object.assign({}, state, {
                loading: true
            });

        case 'PAYMENT_GATEWAYS_INIT_SUCCESS':
            return Object.assign({}, state, {
                authRequestId: action.payload.authRequestId,
                webviewURL: action.payload.webviewURL,
                webviewTitle: action.payload.webviewTitle,
                webviewSubtitle: action.payload.webviewSubtitle,
                loading: false
            });


        /**
         * Check gw
         */
        case 'PAYMENT_GATEWAYS_CHECK_START':
            return Object.assign({}, state, {
                loading: true,
                authStatus: null,
            })

        case 'PAYMENT_GATEWAYS_CHECK_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                authStatus: action.payload.status,
            })

        case 'PAYMENT_GATEWAYS_CHECK_FAILURE':
            return Object.assign({}, state, {
                loading: false,
                authStatus: action.payload.status,
            })
        /**
         * Sync gw
         */

        case 'PAYMENT_GATEWAYS_SYNC_START':
            return Object.assign({}, state, {
                loading: true,
            })

        case 'PAYMENT_GATEWAYS_SYNC_SUCCESS':
            return Object.assign({}, state, {
                success: true,
                loading: false,
            })

        case 'PAYMENT_GATEWAYS_SYNC_FAILURE':
            return Object.assign({}, state, {
                loading: false,
            })
        /**
         * Delete gw
         */

        case 'PAYMENT_GATEWAYS_DELETE_SUCCESS':
            return Object.assign({}, state, {
                delete: true,
            })

        default:
            return state;
    }
}
