import React, { PureComponent } from "react";
import clsx from "clsx";
import { connect } from 'react-redux';
import {
    Card,
    CardHeader,
    CardContent,
    MenuItem,
    FormControl,
    FormHelperText,
    InputLabel,
    Typography,
    Select,
    Divider,
    IconButton,
    Button,
    Paper,
    Chip,
    CircularProgress,
    Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import { ProfessionalActions } from "redux/actions";

class ProfessionalDetails extends PureComponent {
    constructor(props) {
        super(props);
    
        this.state = {
            edit: false,
        };
    }

    handleEditOpen = () => {
        console.warn('sss')
        this.setState({
            edit: true
        })
    }

    handleEditClose = () => {
        this.setState({
            edit: false
        })
    }
  
    render() {
        return (
            <Card className={clsx(this.props.classes.root, this.props.className)}>
                <form autoComplete="off" noValidate>
                    <CardHeader
                        title="Especialidades"
                        subheader={
                            this.props.pendingUser
                                ? "Asignar especialidad/es al profesional"
                                : "Profesional Disponbile"
                        }
                        action={
                            this.props.loading ? (
                                <div className={this.props.classes.loadingComp}>
                                    <CircularProgress size={25} color='secondary' />
                                </div>
                            ) : (
                                !this.props.pendingUser || this.props.userActiveSuccess ?
                                    <IconButton
                                        disabled={
                                            this.props.currentSpecialities.length === 0
                                        }
                                        onClick={this.state.edit
                                            ? () => {
                                                this.props.updateProfessionalSpecialities()
                                                this.handleEditClose()
                                            }
                                            : () => this.handleEditOpen()
                                        }
                                    >
                                        {this.state.edit ? (
                                            <CheckIcon />
                                        ) :  (
                                            <EditIcon />
                                        )}
                                    </IconButton>
                                : (
                                    <Button
                                        type='submit'
                                        disabled={
                                            !this.props.pendingUser
                                                || this.props.userActiveSuccess
                                                || this.props.activateLoading
                                                || this.props.currentSpecialities.length === 0
                                        }
                                        color="primary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.activateProfessional()
                                        }}
                                        variant="contained">

                                        {this.props.activateLoading ? (
                                            <CircularProgress
                                                color='secondary'
                                                size={25}
                                            />
                                        ) : (
                                            "Activar"
                                        )}
                                    </Button>
                                )
                            )
                        }
                    />
            
                    <Divider />

                    <CardContent
                        style={{
                            padding: 0,
                        }}
                    >
                        
                        {this.state.edit || this.props.pendingUser ? (
                            <Box
                                padding={2}
                                borderBottom="1px solid #eee"
                            >
                                {this.props.pendingUser ? (
                                    <Box
                                        marginBottom={2}
                                    >
                                        <Alert severity="warning">
                                            {`Para activar al profesional debe asignarle al menos una especialidad de cartilla`}
                                        </Alert>
                                    </Box>
                                ) : null}

                                <form onSubmit={this.submitNewSpeciality} noValidate>
                                    <FormControl
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                        style={{ width: '100%' }}
                                    >
                                        <Box
                                            marginBottom={1}
                                        >
                                            <Typography>
                                                Seleccione Especialidad
                                            </Typography>
                                        </Box>

                                        <Select
                                            placeholder="seleccionar"
                                            value={null}
                                            onChange={(e) => {
                                                e.preventDefault()
                                                this.props.addNewSpeciality(e.target.value)
                                            }}
                                        >
                                            {this.props.specialities.map(speciality => (
                                                <MenuItem
                                                    disabled={
                                                        this.props.currentSpecialities.find(item => item.id === speciality.id)
                                                    }
                                                    style={{
                                                        padding: 10,
                                                        margin: '4px 0'
                                                    }}
                                                    value={speciality}
                                                >
                                                    {speciality.nombre}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </form>
                            </Box>
                        ): null}

                        <Box
                            padding={2}
                            borderBottom="1px solid #eee"
                        >
                            {this.props.currentSpecialities.map((speciality) => {
                                    return (
                                        <div
                                            key={speciality.id}
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            <Chip
                                                classes={{
                                                    root: clsx(this.props.classes.chip),
                                                    label: clsx(this.props.classes.chipLabel)
                                                }}
                                                label={speciality.nombre}
                                                onDelete={this.state.edit || this.props.pendingUser ?
                                                    () => this.props.handleDeleteSpecialitiesChips(speciality.id)
                                                    : null
                                                }
                                            />
                                        </div>
                                    )
                                })}
                        </Box>
                    </CardContent>
                </form>
            </Card>
        );
    }
}


const mapStateToProps = state => ({
    activateLoading: state.professional.activateLoading,
    specialitiesList: state.professional.specialitiesList,
    userActiveSuccess: state.professional.userActiveSuccess,
    selection: state.professional.selection,
    loading: state.professional.loading,
})

const mapDispatchToProps = dispatch => ({
    addNewSpeciality: (id) => dispatch(ProfessionalActions.addNewSpeciality(id)),
    handleDeleteSpecialitiesChips: (id) => dispatch(ProfessionalActions.handleDeleteSpecialitiesChips(id)),
})


export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalDetails)