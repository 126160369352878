const drawerWidth = 240;
const drawerWidthCollapsed = 72;

const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // transition: theme.transitions.create(['width', 'margin'], {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen,
        // }),
        // boxShadow: '0 0.75rem 1.5rem rgba(18,38,63,.03)',
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidthCollapsed}px)`,
        },
    },
    appBarShift: {
        // transition: theme.transitions.create(['width', 'margin'], {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.enteringScreen,
        // }),
        
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        }
    },
    toolbar: {
        paddingRight: 24,
        justifyContent: "space-between",
        minHeight: 64,
    },
    menuButton: {
        marginRight: 36,
    },
    title: {
        flexGrow: 1,
    },
    iconListItem: {
        width: "auto",
        borderRadius: theme.shape.borderRadius,
        paddingTop: 11,
        paddingBottom: 11,
        marginLeft: theme.spacing(1),
    },
    smBordered: {
        [theme.breakpoints.down("xs")]: {
            borderRadius: "50% !important"
        }
    },
    accountAvatar: {
        backgroundColor: theme.palette.secondary.main,
        height: 24,
        width: 24,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginLeft: theme.spacing(1.5),
            marginRight: theme.spacing(1.5)
        }
    },
    username: {
        paddingLeft: 0,
    },
});


export default styles