import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert variant="filled" {...props} />;
}


class AnAlertBar extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      type: 'error',
      message: "",
      duration: 4500
    }
  }

  show(params) {
    this.setState({
      show: true,
      message: params.message,
      type: params.type
    });
  }

  handleClose = () => {
    this.setState({
      show: false,
      message: ''
    })
  }
  render() {
    return (
      <Snackbar
        open={this.state.show}
        autoHideDuration={this.state.duration}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        style={{ maxWidth: 700 }}
        onClose={this.handleClose}>
        <Alert
          onClose={this.handleClose}
          severity={this.state.type}>
          {this.state.message}
        </Alert>
      </Snackbar>
    );
  }
}

export default AnAlertBar