import InstitucionRestClient from 'http/resources/InstitucionRestClient';
import ErrorManager, { ApiNonFatalError } from 'services/error-manager';

/**
 * Fetch specialities
 */

function fetchSpecialities() {
    return (dispatch) => {
        dispatch({
            type: 'DIRECTORY_FETCH_SPECIALITIES_START'
        })

        new InstitucionRestClient(true).getSpecialities()
            .then(response => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    );
                }
                dispatch({
                    type: 'DIRECTORY_FETCH_SPECIALITIES_SUCCESS',
                    payload: {
                        specialities: response.data.value.listaEspecialidades
                    }
                });
            }).catch(error => {
                dispatch({
                    type: 'DIRECTORY_FETCH_SPECIALITIES_FAILURE',
                });
                
                dispatch({
                    type: 'DASHBOARD_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }
}

function getProfessionalToSpeciality(id) {
    return async (dispatch) => {
        dispatch({
            type: 'DIRECTORY_FETCH_PROFESSIONALS_TO_SPECIALITY_LOADING'
        })
        await new InstitucionRestClient(true).getProfessionalToSpeciality({
            id
        }).then(response => {
            if (response.data.isFaulted) {
                return Promise.reject(
                    new ApiNonFatalError(response)
                );
            }
            dispatch({
                type: 'DIRECTORY_FETCH_PROFESSIONALS_TO_SPECIALITY_SUCC',
                payload: {
                    professionalList: response.data.value.listaMedicos
                }
            })
        }).catch(error => {
            dispatch({
                type: 'DASHBOARD_SET_ERROR',
                payload: {
                    error: ErrorManager.toMessage(error),
                }
            });
        })
    }
}


function setNewSpecialityName(name) {
    return {
        type: 'DIRECTORY_NEW_SPECIALITY_NAME_SET',
        payload: {
            name,
        }
    }
}

function submitNewSpeciality() {
    return (dispatch, getState) => {
        dispatch({
            type: 'DIRECTORY_CREATE_NEW_SPECIALITY_START'
        })
        const { speciality } = getState().directory.create;

        new InstitucionRestClient(true).createNewSpeciality({
            speciality
        }).then(response => {
            if (response.data.isFaulted) {
                return Promise.reject(
                    new ApiNonFatalError(response)
                );
            }
            dispatch({
                type: 'DIRECTORY_CREATE_NEW_SPECIALITY_SUCCESS',
            });
            
            dispatch({
                type: 'DASHBOARD_SET_SUCCESS',
                payload: {
                    message: 'Especialidad creada con éxito'
                }
            });
        }).catch(error => {
            dispatch({
                type: 'DIRECTORY_CREATE_NEW_SPECIALITY_FAILURE',
                payload: {
                    error: ErrorManager.toMessage(error),
                }
            });
        })
    }
}


function deleteSpeciality(id) {
    return (dispatch) => {
        dispatch({
            type: 'DIRECTORY_DELETE_SPECIALITY_START'
        })

        new InstitucionRestClient(true).deleteSpeciality({
            id
        }).then(response => {
            if (response.data.isFaulted) {
                return Promise.reject(
                    new ApiNonFatalError(response)
                );
            }
            dispatch({
                type: 'DIRECTORY_DELETE_SPECIALITY_SUCCESS'
            });
            dispatch({
                type: 'DASHBOARD_SET_SUCCESS',
                payload: {
                    message: 'Especialidad eliminada correctamente'
                }
            });
        }).catch(error => {
            dispatch({
                type: 'DASHBOARD_SET_ERROR',
                payload: {
                    error: ErrorManager.toMessage(error),
                }
            });
        })
    }
}


export const DirectoryActions = {
    fetchSpecialities,
    submitNewSpeciality,
    setNewSpecialityName,
    deleteSpeciality,
    getProfessionalToSpeciality
}