import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';


function Copyright({classes}) {
    return (
        <Typography className={classes.copyrightContainer} variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://doqchat.com/">
                For Each SRL
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}   
        </Typography>
    );
}

export default Copyright
