import React from "react";
import MUIDataTable from "mui-datatables";
import { useHistory } from "react-router-dom";
import { Typography } from '@material-ui/core';

export default function SpecialityProfessionalList(props) {
  const { data, titles } = props;

  const history = useHistory()

  const columns = [
    {
      name: "nombre",
      label: "Profesionales",
      options: {
        filterType: "textField",
        customBodyRender: (val) => {
            return (
                <Typography variant="inherit">
                  {val}
                </Typography>
              )
        },
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    responsive: "stacked",
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    search: false,
    selectableRows: 'single',
    fixedHeaderOptions: {
        yAxis: false,
        xAxis: false,
    },
    selectableRows: false,
    onRowClick: (rowData, { dataIndex, rowIndex }) => {
        history.push(`/dashboard/profesionales/${props.data[dataIndex].id}`)
    },
  };

  return (
    <MUIDataTable
      title={""}
      data={data}
      columns={columns}
      options={options}
    />
  );
}
