import InstitucionRestClient from "http/resources/InstitucionRestClient";
import ErrorManager, { ApiNonFatalError } from 'services/error-manager';

function fetchInvitations() {
    return dispatch => {
        dispatch({
            type: "PROFESSIONALS_FETCH_INVITATIONS_START",
        })

        new InstitucionRestClient(true).getPendingInvitationList()
            .then((response) => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    );
                }

                dispatch({
                    type: "PROFESSIONALS_FETCH_INVITATIONS_SUCCESS",
                    payload: {
                        invitations: response.data.value.listaSolicitudesVinculacion,
                    }
                })
            })

            .catch(error => {
                dispatch({
                    type: "PROFESSIONALS_FETCH_INVITATIONS_FAILURE",
                })
                dispatch({
                    type: 'DASHBOARD_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }
}

function handleInput(name, value) {
    let formatValue = value;
    const separators = [' ', ';', ','];

    if (typeof value === 'string') {
        formatValue = [
            ...new Set(
                value
                    .replace(/\n/g, ' ')
                    .split(new RegExp(separators.join('|'), 'g'))
                    .filter(item => (item.length > 1 ? item : null))
            ),
        ]

    }

    const emailValidation = email =>
        //eslint-disable-next-line
        /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/.test(email)


    const emailList = formatValue.map(item =>
        emailValidation(item)
            ? { email: item, validate: true }
            : { email: item, validate: false }
    )

    const validateEmail = formatValue
        .filter(item => emailValidation(item))

    return {
        type: 'INVITATIONS_HANDLE_INPUT',
        payload: {
            name,
            value,
            emailList,
            validateEmail
        }
    }
}

function sendInvitations() {
    return (dispatch, getState) => {

        const { validateEmail } = getState().invitations

        dispatch({
            type: 'INVITATIONS_LOADING'
        })

        new InstitucionRestClient(true).sendInvitation({
            list: validateEmail
        })
            .then(response => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    );
                }
                dispatch({
                    type: 'INVITATIONS_IS_SUCCESS'
                })
            })
            .catch(error => {
                dispatch({
                    type: 'INVITATIONS_IS_FAILURE',
                });
                dispatch({
                    type: 'DASHBOARD_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }
}

function clear() {
    return {
        type: 'INVITATIONS_CLEAR'
    }
}
export const InvitationsActions = {
    fetchInvitations,
    sendInvitations,
    handleInput,
    clear
}