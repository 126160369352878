import React, { PureComponent } from "react";
import clsx from "clsx";
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Avatar,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    IconButton,
    Box,
    Divider,
    Chip,
    Icon,
    Link,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import LinkOff from "@material-ui/icons/LinkOff";
import { ReportsActions } from "redux/actions";
import { connect } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';


class ProfessionalProfile extends PureComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            open: false,
        };
    } 

    handleViewConsultations = () => {
        this.props.setProfessionalId(this.props.match.params.code);
        this.props.history.push('/dashboard/reportes')
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleOpen = () => {
        this.setState({
            open: true,
        });
    };

    unlinkProfessional = () => {
        this.props.unlinkProfessional(this.props.match.params.code);
        this.handleClose();
    };

    render() {
        const { classes, className, ...rest } = this.props;

        return (
            <React.Fragment>
                <Card {...rest} className={clsx(classes.root, className)}>
                    <CardHeader
                        title={
                            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                <Box display="flex" flex={1}> 
                                    <Typography variant="h5">
                                        Perfil
                                    </Typography>
                                </Box>

                                <Box>
                                    <Chip
                                        style={{
                                            minWidth: 100,
                                        }}
                                        size="large"
                                        label={this.props.status === 1 ? "Pendiente de activación" : this.props.status === 2 ? "Activo" : "Desvinculado"}
                                        color={this.props.status === 1 ? "secondary" : this.props.status === 2 ? "primary" : "default"}
                                    />
                                </Box>
                            </Box>
                        }
                    />
          
                    <Divider />
          
                    <CardContent
                        style={{
                            position: 'relative'
                        }}
                    >
                        <Box flexDirection="row" display="flex">
                            <Avatar className={classes.avatar} src={this.props.avatar} />
                        
                            <Box display="flex" justifyContent="center" flexDirection="column" ml={2}>
                                <Typography gutterBottom variant="h4">
                                    {this.props.name}
                                </Typography>
                    
                                
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Link
                                        href={this.props.webcard}
                                        target="_blank"
                                        style={{
                                            marginRight: 10,
                                        }}
                                    >
                                        {this.props.webcard}
                                    </Link>

                                    {(this.state.copied ? (
                                        <Icon
                                            style={{
                                                color: 'green'
                                            }}
                                        >
                                            check
                                        </Icon>
                                    ) : (
                                        <CopyToClipboard
                                            text={this.props.webcard}
                                            onCopy={() => {
                                                this.setState({
                                                    copied: true
                                                }, () => {
                                                    setTimeout(() => {
                                                        this.setState({
                                                            copied: false,
                                                        })
                                                    }, 1500)
                                                })
                                            }}
                                        >
                                            <Icon>
                                                content_copy
                                            </Icon>
                                        </CopyToClipboard>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>

                    <Divider />

                    <CardActions align="right">
                        <Button
                            onClick={this.handleViewConsultations}

                            type="submit"
                            variant="outlined"
                            color="default"
                            size="large"
                        >
                            <Typography
                                style={{}}
                            >
                                Ver Consultas
                            </Typography>
                        </Button>

                        <Button
                            onClick={this.handleOpen}

                            type="submit"
                            variant="outlined"
                            color="default"
                            size="large"
                        >
                            <Typography
                                style={{}}
                            >
                                Desvincular
                            </Typography>
                        </Button>
                    </CardActions>
                </Card>

                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle id="alert-dialog-title">
                        ¿Está seguro que desea desvincular al profesional?
                    </DialogTitle>
                    
                    <DialogContent>
                        <DialogContentText>
                            <Alert severity="warning">
                                {`Si desvincula al profesional '${this.props.name}' el mismo no podrá atender ningún paciente con la app DoqChat por medio de la institución.`}
                            </Alert>
                        </DialogContentText>
                    </DialogContent>
    
                    <DialogActions>
                        <Button onClick={this.handleClose}>
                            Cancelar
                        </Button>
                        <Button onClick={this.unlinkProfessional} color="primary">
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    professionalId: state.reports.professionalId
});

const mapDispatchToProps = (dispatch) => ({  
    setProfessionalId: (id) => dispatch(ReportsActions.setProfessionalId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalProfile);
