import React from "react";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { useTheme } from '@material-ui/styles';
import { useMediaQuery, Typography, Box } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import { useHistory } from "react-router-dom";

export default function DirectoryList(props) {
  const { data } = props;

  const theme = useTheme();
  const history = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  
  const columns = [
    {
      name: "nombre",
      label: "Especialidades",
      options: {
        filterType: "textField",
        filter: false,
        sort: false,
        customBodyRender: (val) => {
          return (
            <Typography variant="inherit">
              {val}
            </Typography>
          )
        },
      },
    },
  ];

  const options = {
    responsive: "stacked",
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    search: false,
    selectableRows: 'single',
    fixedHeaderOptions: {
        yAxis: false,
        xAxis: false,
    },
    selectableRows: false,
    onRowClick: (rowData, { dataIndex, rowIndex }) => {
        history.push(`/dashboard/cartilla/${props.data[dataIndex].nombre.replace(/\s+/g, '-').toLowerCase()}?id=${props.data[dataIndex].id}`)
    },
  }

  return (    
    <MUIDataTable
      title={""}
      data={data}
      columns={columns}
      options={options}
    />
  );
}
