const defaultState = {
    fetch: {
        loading: false,
        error: false,
    },
    create: {
        loading: false,
        error: null,
        success: false,
        speciality: '',
    },
    remove: {
        loading: false,
        error: null,
        success: false,
    },
    loading: false,
    success: false,
    removed: false,
    specialities: [],
    professionalList: [],
    error: null
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'DIRECTORY_FETCH_SPECIALITIES_START':
            return Object.assign({}, state, {
                fetch: Object.assign({}, state.fetch, {
                    loading: true,
                    error: false,
                }),
                specialities: [],
            })

        case 'DIRECTORY_FETCH_SPECIALITIES_SUCCESS':
            return Object.assign({}, state, {
                fetch: Object.assign({}, state.fetch, {
                    loading: false,
                }),
                specialities: action.payload.specialities,
            })

        case 'DIRECTORY_FETCH_SPECIALITIES_FAILURE':
            return Object.assign({}, state, {
                fetch: Object.assign({}, state.fetch, {
                    loading: false,
                    error: true,
                })
            })



        case 'DIRECTORY_CREATE_NEW_SPECIALITY_START':
            return Object.assign({}, state, {
                create: Object.assign({}, state.create, {
                    loading: true,
                    error: false,
                    success: false,
                })
            });
        
        case 'DIRECTORY_CREATE_NEW_SPECIALITY_SUCCESS':
            return Object.assign({}, state, {
                create: Object.assign({}, state.create, {
                    loading: false,
                    success: true,
                })
            });

        case 'DIRECTORY_CREATE_NEW_SPECIALITY_FAILURE':
            return Object.assign({}, state, {
                create: Object.assign({}, state.create, {
                    loading: false,
                    error: action.payload.error
                })
            });

        
        
        case 'DIRECTORY_FETCH_PROFESSIONALS_TO_SPECIALITY_LOADING':
            return Object.assign({}, state, {
                loading: true,
                removed: false
            });

        case 'DIRECTORY_FETCH_PROFESSIONALS_TO_SPECIALITY_SUCC':
            return Object.assign({}, state, {
                professionalList: action.payload.professionalList,
                loading: false
            });


        case 'DIRECTORY_DELETE_SPECIALITY_START':
            return Object.assign({}, state, {
                loading: true,
            })

        case 'DIRECTORY_DELETE_SPECIALITY_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                removed: true,
                error: null,
            })

        case 'DIRECTORY_DELETE_SPECIALITY_FAILURE':
            return Object.assign({}, state, {
                loading: false,
            })

        case 'DIRECTORY_NEW_SPECIALITY_NAME_SET':
            return Object.assign({}, state, {
                create: Object.assign({}, state.create, {
                    speciality: action.payload.name,
                })
            });

        default:
            return state;
    }
}
