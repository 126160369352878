const defaultState = {
    loading: true,
    data: null,

    activateLoading: false,
    userActiveSuccess: false,
    update: false,
    selection: null,
    error: null,
    success: false,
    unlink: {
        loading: false,
        error: null,
    }
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'PROFESSIONAL_FETCH_PROFESSIONAL_START':
            return Object.assign({}, state, {
                loading: true,
            })
        
        case 'PROFESSIONAL_FETCH_PROFESSIONAL_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: { ...action.payload.professional },
            })

        case 'PROFESSIONAL_FETCH_PROFESSIONAL_FAILURE':
            return Object.assign({}, state, {
                loading: false,
            })

        
        case 'PROFESSIONAL_UNLINK_START':
            return Object.assign({}, state, {
                unlink: Object.assign({}, state.unlink, {
                    loading: true,
                })
            })

        
        case 'PROFESSIONAL_UNLINK_SUCCESS':
            return Object.assign({}, state, {
                unlink: Object.assign({}, state.unlink, {
                    loading: false,
                }),
                data: Object.assign({}, state.data, {
                    status: 3,
                })
            })

        case 'PROFESSIONAL_UNLINK_FAILURE':
            return Object.assign({}, state, {
                unlink: Object.assign({}, state.unlink, {
                    loading: false,
                })
            })




        case 'PROFESSIONAL_UPDATE_SPECIALITIES_LOADING':
            return Object.assign({}, state, {
                loading: true,
                update: false
            })

        case 'PROFESSIONAL_UPDATE_SPECIALITIES_SUCCESS':
            return Object.assign({}, state, {
                update: true,
                loading: false
            })

        case 'PROFESSIONAL_UPDATE_SPECIALITIES_FAILURE':
            return Object.assign({}, state, {
                loading: false,
                update: true,
            })

        case 'PROFESSIONAL_ACTIVATE_USER_LOADING':
            return Object.assign({}, state, {
                activateLoading: true,
            })

        case 'PROFESSIONAL_ACTIVATE_USER_SUCCESS':
            return Object.assign({}, state, {
                userActiveSuccess: true,
                activateLoading: false,
                data: Object.assign({}, state.data, {
                    status: 2
                })
            })

        case 'PROFESSIONAL_DELETE_SPECIALITY_TO_EDIT':
            const specialities = [].concat(state.data.specialities).filter(speciality => speciality.id !== action.payload.id)
            
            console.warn('sssss', specialities)

            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    specialities: [].concat(specialities),
                })
            })

        case 'PROFESSIONAL_ADD_SPECIALITY_TO_EDIT':
            if (state.data.specialities.find((item) => item.id === action.payload.speciality.id)) {
                return state
            }

            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    specialities: [].concat(action.payload.speciality).concat(state.data.specialities)
                })
            })

        case 'PROFESSIONAL_RESET_STATE':
            return Object.assign({}, state, {
                ...defaultState
            })


        default:
            return state;
    }
}
