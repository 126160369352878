const defaultState = {
    loading: false,
    error: false,
    professionals: [],
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'PROFESSIONALS_FETCH_LINKED_PROFESSIONAL_START':
            return Object.assign({}, state, {
                loading: true,
                error: false,
                professionals: [],
            })

        case 'PROFESSIONALS_FETCH_LINKED_PROFESSIONAL_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                professionals: action.payload.professionals
            })
        
        case 'PROFESSIONALS_FETCH_LINKED_PROFESSIONAL_FAILURE':
            return Object.assign({}, state, {
                loading: false,
                error: true,
            })

        case 'PROFESSIONALS_RESET_STATE':
            return Object.assign({}, state, {
                ...defaultState
            })


        default:
            return state;
    }
}
