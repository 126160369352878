
const styles = theme => ({
    root: {
        height: `calc(100% - 20)`,
    },
    container: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 4,
    },
    // root: {
    //     display: 'flex',
    // },
    // head: {
    //     width: 150,
    // },
    // content: {
    //     flexGrow: 1,
    //     overflow: 'auto',
    // },
    // container: {
    //     paddingTop: theme.spacing(2),
    //     paddingBottom: theme.spacing(4),
    // },
    // tableRow: {
    //     '&:nth-of-type(even)': {
    //         backgroundColor: '#f8f9fa',
    //     },
    // },
    // tableBody: {
    //     fontSize: 14,
    //     borderWidth: 0,
    // },
})


export default styles