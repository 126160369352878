export const drawerListItemsPrimary = [
    {
        title: 'Profesionales',
        subtitle: 'Información de profesionales',
        to: '/dashboard/profesionales',
        icon: 'people'
    },

    {
        title: 'Invitaciones',
        subtitle: 'Invitaciones',
        to: '/dashboard/invitaciones',
        icon: 'send'
    },

    {
        title: 'Cartilla',
        subtitle: 'Ver y editar cartilla única',
        to: '/dashboard/cartilla',
        icon: 'assignment'
    },

    {
        title: 'Reportes',
        subtitle: 'Buscar con consultas por filtro',
        to: '/dashboard/reportes',
        icon: 'find_in_page_sharp'
    },
    {
        title: 'Perfil',
        subtitle: 'Editar perfil',
        to: '/dashboard/perfil',
        icon: 'account_circle'
    },
]

export const drawerListItemsSecondary = [
    
]
