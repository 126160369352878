import RestClient from 'http/RestClient'
import consultationsListData from "assets/data/consultationsList.json";

export default class InstitucionRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(undefined,
            useCredentials,
            refreshContext)
    }

    /*
     *   Init authorization app with email and code
     */

    getUser(params) {
        const url = `${this.baseURL}/instituciones/obtenerusuarioparalogin`;

        return this.instance.get(url, {
            params: {
                email: params.email,
                codigo: params.code
            }
        })
    }

    /*
     *   Login activated user
     */

    loginUser(params) {
        const url = `${this.baseURL}/instituciones/loginusuario`;

        return this.instance.post(url, {
            usuarioId: params.userId,
            pass: params.password,
            uuid: params.uuid,
            tokenFCM: params.tokenFCM,
            tokenPushy: params.tokenPushy
        });
    }

    /*
     *   Activate user - create password
     */

    activateUser(params) {
        const url = `${this.baseURL}/instituciones/activarusuario`;

        return this.instance.post(url, {
            usuarioId: params.userId,
            pass: params.password,
        });
    }


    /*
     *   Send invitation 
     */

    sendInvitation(params) {
        const url = `${this.baseURL}/instituciones/enviarsolicitudesvinculacion`;

        return this.instance.post(url, {
            listaEmails: params.list
        });
    }

    /*
    *  Obtain own professionals list 
    */

    getProfessionalsList() {
        const url = `${this.baseURL}/instituciones/obtenermedicosinstitucion`;

        return this.instance.get(url)
    }

    /*
    *  Obtain pending invitation list 
    */

    getPendingInvitationList() {
        const url = `${this.baseURL}/instituciones/obtenersolicitudesvinculacion`;

        return this.instance.get(url)
    }

    /*
    * Activate professional
    */

    activateProfessional(params) {
        const url = `${this.baseURL}/instituciones/activarconfirmacionvinculacion`
        return this.instance.post(url, {
            compartirId: params.id,
            listaEspecialidades: params.specialitiesList
        })
    }

    /*
    * Get sigle professional data
    */

    getProfessional(params) {
        const url = `${this.baseURL}/instituciones/obtenermedicoinstitucion`
        return this.instance.get(url, {
            params: {
                compartirId: params.id,
            }
        })
    }

    /*
    * Get all specialities
    */

    getSpecialities() {
        const url = `${this.baseURL}/instituciones/obtenerespecialidades`
        return this.instance.get(url)
    }

    /*
    * Create new speciality
    */

    createNewSpeciality(params) {
        const url = `${this.baseURL}/instituciones/crearespecialidad`
        return this.instance.post(url, {
            nombre: params.speciality
        })
    }

    /*
    * Get professional list to speciality
    */

    getProfessionalToSpeciality(params) {
        const url = `${this.baseURL}/instituciones/obtenermedicosespecialidad`
        return this.instance.get(url, {
            params: { especialidadId: params.id }
        })
    }

    /*
    * Delete an speciality
    */

    deleteSpeciality(params) {
        const url = `${this.baseURL}/instituciones/borrarespecialidad`
        return this.instance.post(url, {
            especialidadId: params.id
        })
    }

    /**
     * Update professional's specialities 
     */

    updateProfessionalSpecialities(params) {
        const url = `${this.baseURL}/instituciones/editarespecialidadesmedico`

        return this.instance.post(url, {
            compartirId: params.id,
            listaEspecialidades: params.specialitiesListToModify
        });
    }

    unlinkProfessional(params) {
        const url = `${this.baseURL}/instituciones/borrarasociacionmedico`

        return this.instance.post(url, {
            compartirId: params.id
        })
    }

    /*
    * Init Authorization Request Gateway
    */

    initAuthorizationRequestGateway(params) {
        const url = `${this.baseURL}/pagos/iniciarautorizacionrequestgateway`

        return this.instance.post(url, {
            gateway: params.gateway,
        });
    }

    /**
     * Check auth request gateway
     */


    checkAuthorizationRequestGateway(params) {
        const url = `${this.baseURL}/pagos/controlarautorizacionrequestgateway`

        return this.instance.get(url, {
            params: {
                authRequestId: params.authRequestId
            }
        });
    }

    /**
     * Sync gateway
     */

    syncGateway(params) {
        const url = `${this.baseURL}/instituciones/asociargatewayautorizado`

        return this.instance.post(url, {
            authRequestId: params.authRequestId
        });
    }

    /**
     * Get gateway
     */

    getAvailableGateway() {
        const url = `${this.baseURL}/instituciones/obtenergatewayAuthInstitucion`

        return this.instance.get(url);
    }

    /**
     * Delete gateway
     */

    deleteGateway() {
        const url = `${this.baseURL}/instituciones/desautorizargatewayinstitucion`

        return this.instance.post(url, {});
    }

    /*    
    * Get list of consultations by filter
    */

    searchConsultations(params) {       
        // console.log("searchConsultations >" + JSON.stringify(params))
        // var data = consultationsListData
        // if(params.page !== null)
        //   data.value.resultado.page = params.page
        // if(params.pageCount !== null)
        //   data.value.resultado.pageCount = params.pageCount      
        
        // return Promise.resolve(
        //     {
        //         data: data
        //     }           
        // );
        
        const url = `${this.baseURL}/instituciones/buscarconsultas`
        return this.instance.get(url, {
            params: {
                compartirId: params.id,
                desde: params.from,
                hasta: params.to,
                page: params.page,
                pageCount: params.pageCount            
            }
        })
    
  }
}