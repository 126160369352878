// @flow
import { Countries, InvitationState, Languages, Titles } from '../../constants';
import InstitucionRestClient from 'http/resources/InstitucionRestClient';
import ErrorManager, { ApiNonFatalError } from 'services/error-manager';

/**
 *  Fetch countries.
 */
function fetchCountries() {
    return async (dispatch) => {
        dispatch({
            type: 'CONFIGURATION_FETCH_COUNTRIES_SUCCESS',
            payload: {
                countries: Countries,
            },
        });
    };
}

/**
 *  Fetch invitation states.
 */
function fetchInvitationStates() {
    return async (dispatch) => {
        dispatch({
            type: 'CONFIGURATION_FETCH_INVITATION_STATE_SUCCESS',
            payload: {
                invitationStates: InvitationState,
            },
        });
    };
}

/**
 *  Fetch languages.
 */
function fetchLanguages() {
    return async (dispatch) => {
        dispatch({
            type: 'CONFIGURATION_FETCH_LANGUAGES_SUCCESS',
            payload: {
                languages: Languages,
            },
        });
    };
}

/**
 *  Fetch titles.
 */
function fetchTitles() {
    return async (dispatch) => {
        dispatch({
            type: 'CONFIGURATION_FETCH_TITLES_SUCCESS',
            payload: {
                titles: Titles,
            },
        });
    };
}

/**
 * Fetch specialities
 */

function fetchSpecialities() {
    return (dispatch) => {
        dispatch({
            type: 'CONFIGURATION_FETCH_SPECIALITIES_LOADING'
        })

        new InstitucionRestClient(true).getSpecialities()
            .then(response => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    );
                }
                dispatch({
                    type: 'CONFIGURATION_FETCH_SPECIALITIES_SUCCESS',
                    payload: {
                        specialities: response.data.value.listaEspecialidades
                    }
                });
            }).catch(error => {
                dispatch({
                    type: 'DASHBOARD_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }
}


export const ConfigurationActions = {
    fetchCountries,
    fetchLanguages,
    fetchTitles,
    fetchInvitationStates,
    fetchSpecialities
};
