const styles = theme => ({
    alertMessage: {
        padding: 10,
        borderLeft: '5px solid #fff',
        marginBottom: 5,
        marginTop: 5
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});


export default styles