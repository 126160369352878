import React from "react";
import MUIDataTable from "mui-datatables";
import { format } from 'date-fns'

export default function InvitationsList(props) {
  const { data, invitationStates } = props;

  const columns = [
    {
      name: "email",
      label: "Email",
      options: {
        filterType: "textField",
        filter: true,
        sort: true,
      },
    },
    {
      name: "fechaHoraEnvio",
      label: "Fecha envío",
      options: {
        filterType: "textField",
        filter: true,
        sort: true,
      },
    },
    {
      name: "fechaHoraVencimiento",
      label: "Fecha vencimiento",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "estado",
      label: "Estado",
      options: {
        filterType: "multiselect",
        filter: true,
        sort: false,
      },
    },
  ];

  function getData() {
    return data.map((row) => {
      return columns.map((column) => {
        switch (column.name) {
          case "email":
            return (row[column.name] || "");
          case "fechaHoraEnvio":
          case "fechaHoraVencimiento":
            return format(new Date(row[column.name]), "dd/MM/yy HH:mm:ss");
          case "estado":
            return invitationStates[row[column.name]];
          default:
            return (row[column.name] || "");
        }
      });
    });
  }

  const options = {
    responsive: "stacked",
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    search: false,
    selectableRows: 'single',
    // textLabels: TextLabelsTable,
    // selectableRowsHeader: false,
    // disableToolbarSelect: true,
    fixedHeaderOptions: {
        yAxis: false,
        xAxis: false,
    },
    selectableRows: false,
  };

  return (
    <MUIDataTable
      title={""}
      data={getData()}
      columns={columns}
      options={options}
    />
  );
}
