import InstitucionRestClient from "http/resources/InstitucionRestClient"
import ErrorManager, { ApiNonFatalError } from 'services/error-manager';
import { TitlesMap } from '../../constants'

function fetchProfessional(id) {
    return async dispatch => {
        dispatch({
            type: "PROFESSIONAL_FETCH_PROFESSIONAL_START",
        })

        new InstitucionRestClient(true).getProfessional({
            id
        })
            .then(response => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    );
                }

                dispatch({
                    type: 'PROFESSIONAL_FETCH_PROFESSIONAL_SUCCESS',
                    payload: {
                        professional: {
                            id: response.data.value.id,
                            name: `${TitlesMap[response.data.value.titulo]} ${response.data.value.nombre} ${response.data.value.apellido}`,
                            status: response.data.value.estado,
                            webcard: response.data.value.webCard,
                            specialities: response.data.value.especialidades
                        }
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: 'DASHBOARD_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }
}

function activateProfessional(id, specialities) {
    return async dispatch => {
        dispatch({
            type: 'PROFESSIONAL_ACTIVATE_USER_LOADING',
        })
        
        new InstitucionRestClient(true).activateProfessional({
            id,
            specialitiesList: specialities.map((item) => item.id)
        })
            .then(response => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    );
                }
                dispatch({
                    type: 'PROFESSIONAL_ACTIVATE_USER_SUCCESS',
                })
            })
            .catch(error => {
                dispatch({
                    type: 'DASHBOARD_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }
}

function updateProfessionalSpecialities(id, specialities) {
    return async dispatch => {
        dispatch({
            type: 'PROFESSIONAL_UPDATE_SPECIALITIES_LOADING',
        })
        new InstitucionRestClient(true).updateProfessionalSpecialities({
            id,
            specialitiesListToModify: specialities.map((item) => item.id)
        })
            .then(response => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    );
                }
                dispatch({
                    type: 'PROFESSIONAL_UPDATE_SPECIALITIES_SUCCESS',
                })
                dispatch({
                    type: 'DASHBOARD_SET_SUCCESS',
                    payload: {
                        message: 'El perfil del profesional se actualizó correctamente',
                    }
                });
            })

            .catch(error => {
                dispatch({
                    type: 'PROFESSIONAL_UPDATE_SPECIALITIES_FAILURE',
                });
                dispatch({
                    type: 'DASHBOARD_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }
}

function unlinkProfessional(id) {
    return async dispatch => {
        dispatch({
            type: 'PROFESSIONAL_UNLINK_START',
        })

        new InstitucionRestClient(true).unlinkProfessional({
            id,
        })
            .then((response) => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    )
                }

                dispatch({
                    type: 'PROFESSIONAL_UNLINK_SUCCESS'
                })

                dispatch({
                    type: 'DASHBOARD_SET_SUCCESS',
                    payload: {
                        message: 'El perfil ha sido desvinculado de la institución',
                    }
                });
            })

            .catch(error => {
                dispatch({
                    type: 'PROFESSIONAL_UNLINK_FAILURE',
                });
                dispatch({
                    type: 'DASHBOARD_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }
}

function handleDeleteSpecialitiesChips(id) {
    return {
        type: 'PROFESSIONAL_DELETE_SPECIALITY_TO_EDIT',
        payload: {
            id
        }
    }
}

function addNewSpeciality(speciality) {
    return {
        type: 'PROFESSIONAL_ADD_SPECIALITY_TO_EDIT',
        payload: {
            speciality
        }
    }
}

function resetProfessionalState() {
    return {
        type: 'PROFESSIONAL_RESET_STATE'
    }
}


export const ProfessionalActions = {
    fetchProfessional,
    resetProfessionalState,
    activateProfessional,
    unlinkProfessional,
    handleDeleteSpecialitiesChips,
    updateProfessionalSpecialities,
    addNewSpeciality,
}