import { combineReducers } from 'redux'
import context from './context'
import login from './login'
import dashboard from './dashboard'
import professionals from './professionals'
import professional from './professional'
import invitations from './invitations'
import configuration from './configuration'
import directory from './directory'
import gateway from './gateway'
import reports from './reports'

const rootReducer = combineReducers({
    dashboard,
    context,
    login,
    professionals,
    professional,
    invitations,
    configuration,
    directory,
    gateway,
    reports,
})

export default rootReducer;