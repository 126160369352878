import React from 'react';
import appIcon from 'assets/img/app.png';
import {
    Typography,
    Box,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    CircularProgress,
    Button,
    CssBaseline,
    Paper
} from '@material-ui/core';
import { connect } from 'react-redux'
import Copyright from 'components/Copyright'
import { LoginActions } from 'redux/actions/login';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

class LoginScreen extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            openDialog: false,
        };
    }
    componentDidMount() {
        if (this.props.context.user) {
            this.props.history.push('/dashboard/profesionales')
        }
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.context.user && this.props.context.user !== prevProps.context.user) {
            this.props.history.push('/dashboard/profesionales')
        }
    }

    handleChange = (e) => {
        this.props.handleChangeLogin(e.target.name, e.target.value)
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.step === 1) {
            this.props.getUser(this.props.email, this.props.code)
        } else if (this.props.step === 2) {
            this.props.loginUser(this.props.password)
        } else if (this.props.step === 3) {
            this.props.activateUser()
        }

    };

    handleClose = () => {
        this.setState({
            ...this.state,
            openDialog: false
        })
    }
    
    renderInitLoginComp = () => (
        <form noValidate onSubmit={this.handleSubmit}>
            <TextField
                InputLabelProps={{
                    shrink: true,
                }}
                variant="filled"
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                value={this.props.email}
                autoComplete="email"
                autoFocus
                onChange={this.handleChange}
                color='secondary'
                error={!!this.props.error}
            />

            <TextField
                InputLabelProps={{
                    shrink: true,
                }}
                variant="filled"
                margin="normal"
                required
                fullWidth
                name="code"
                label="Código de Institución"
                type="code"
                id="code"
                value={this.props.code}
                onChange={this.handleChange}
                color='secondary'
                error={!!this.props.error}
            />

            <Box mt={2}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    className={this.props.classes.button}
                    disabled={
                        this.props.loading || (
                            !this.props.code || !this.props.email
                        )
                    }
                    fullWidth
                >
                    {this.props.loading ? (
                        <CircularProgress color='secondary' size={25} />
                    ) : (
                        'Ingresar'
                    )}
                </Button>
            </Box>
        </form>
    )

    renderLoginUser = () => (
        <form noValidate onSubmit={this.handleSubmit}>
            <div
                style={{
                    textAlign: 'center',
                }}
            >
                <IconButton
                    style={{
                        background: '#f1f1f1',
                    }}
                    onClick={() => this.props.handleBackButton(1)}
                >
                    <ArrowBackIcon />
                </IconButton>
            </div>
            
            <TextField
                InputLabelProps={{
                    shrink: true,
                }}
                variant="filled"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                value={this.props.password}
                autoComplete="current-password"
                onChange={this.handleChange}
                color='secondary'
            />
            
            <Box mt={2}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={
                        this.props.loading
                    }
                    fullWidth
                >
                    {this.props.loading ? (
                        <CircularProgress color='secondary' size={25} />
                    ) : (
                        'Ingresar'
                    )}
                </Button>
            </Box>
            
            {/* <Box align="center" mt={3}>
                <Button onClick={() => this.setState({ ...this.state, openDialog: true })} size='small'>
                    Recuperar contraseña
                </Button>
            </Box> */}
        </form>
    )

    renderActivateUser = () => (
        <form noValidate onSubmit={this.handleSubmit}>
            <div
                style={{
                    textAlign: 'center',
                }}
            >
                <IconButton
                    style={{
                        background: '#f1f1f1',
                    }}
                    onClick={() => this.props.handleBackButton(1)}
                >
                    <ArrowBackIcon />
                </IconButton>
            </div>
            
            <TextField
                InputLabelProps={{
                    shrink: true,
                }}
                variant="filled"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Crear contraseña"
                type="password"
                id="password"
                value={this.props.password}
                autoComplete="current-password"
                onChange={this.handleChange}
                color='secondary'
            />

            <Box mt={2}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={
                        this.props.loading
                    }
                    fullWidth
                >
                    {this.props.loading ? (
                        <CircularProgress color='secondary' size={25} />
                    ) : (
                        'Activar usuario'
                    )}
                </Button>
            </Box>
        </form>
    )

    renderActivateUserSuccess = () => (
        <Box mt={3}>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={
                    this.props.loading
                }
                fullWidth
                onClick={() => this.props.handleBackButton(1)}
            >
                Ir al login
            </Button>
        </Box>
    )


    render() {
        const {
            classes,
            step,
            error
        } = this.props;

        if (this.props.context.loading) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress
                        size={50}
                        color='primary'
                    />
                </Box>
            )
        }

        return (
            <div className={classes.loginBkg}>
                <div style={{width: 350}}>
                    <Paper variant="outlined" elevation={5}>
                        <div className={classes.header}>
                            <img src={appIcon} alt="doqchat" className={classes.appImage} />
                        </div>

                        <div className={classes.paperIwrap} >
                            <Box>
                                <Typography component="h1" variant="h4" color="primary" align="center">
                                    {step === 1 || step === 2 
                                        ? "INGRESAR"
                                        : step === 3
                                            ? "ACTIVAR USUARIO"
                                            : "USUARIO ACTIVADO"
                                    }
                                </Typography>

                                <Box mt={1}>
                                    {(step === 1) && (
                                        <Typography variant="h6" align="center">
                                            Ingrese sus credenciales
                                        </Typography>
                                    )}
                                    {(step === 2) && (
                                        <Typography variant="h6" align="center">
                                            Ingrese su clave
                                        </Typography>
                                    )}
                                    {(step === 3) && (
                                        <Typography variant="h6" align="center">
                                            Su usuario aún no ha sido activado, debe crear una clave de acceso
                                            para ingresar
                                        </Typography>
                                    )}

                                    {(step === 4) && (
                                        <Typography variant="h6" align="center">
                                            Su usuario ha sido activado, a partir de ahora puede loguearse
                                            en el sistema utilizando la clave que ha elegido en la activación
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            
                            <Box mt={2}>
                                {(step === 1) && this.renderInitLoginComp()}
                                {(step === 2) && this.renderLoginUser()}
                                {(step === 3) && this.renderActivateUser()}
                                {(step === 4) && this.renderActivateUserSuccess()}
                            </Box>

                            {!!error && (
                                <Box
                                    mt={2}
                                    p={1}
                                    bgcolor="error.ultraLight"
                                    borderColor="error.dark"
                                    borderRadius={4}
                                    align="center"
                                >
                                    <Typography variant="caption" color="error">{error}</Typography>
                                </Box>
                            )}
                            
                            <Box mt={8}>
                                <Copyright />
                            </Box>

                            <Dialog
                                open={this.state.openDialog}
                                onClose={this.handleClose}
                            >
                                <DialogTitle>Recuperar contraseña</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Ingrese su correo electrónico para verificar la cuenta. Le enviaremos un email para que pueda recuperar su contraseña
                                    </DialogContentText>
                                    
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        autoFocus
                                        variant="filled"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="emailRestore"
                                        name="emailRestore"
                                        label="Correo electrónico"
                                        type="email"
                                        autoComplete="email"
                                        onChange={this.handleChange}
                                        value={this.props.emailRestore}
                                        fullWidth
                                    />
                                </DialogContent>
                                
                                <DialogActions>
                                    <Button
                                        onClick={this.handleClose}
                                    >
                                        Cancelar
                                    </Button>
                                    
                                    <Button
                                        onClick={this.handleClose}
                                        color="primary"
                                        disabled={
                                            !this.props.emailRestore
                                        }
                                    >
                                        Enviar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </Paper>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.login.loading,
    error: state.login.error,
    email: state.login.email,
    code: state.login.code,
    password: state.login.password,
    step: state.login.step,
    emailRestore: state.login.emailRestore,
    context: state.context,
});

const mapDispatchToProps = (dispatch) => ({
    getUser: (email, code) => dispatch(LoginActions.getUser(email, code)),
    loginUser: (password) => dispatch(LoginActions.loginUser(password)),
    activateUser: () => dispatch(LoginActions.activateUser()),
    handleChangeLogin: (name, value) => dispatch(LoginActions.handleChangeLogin(name, value)),
    handleBackButton: (to) => dispatch(LoginActions.handleBackButton(to)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);