const defaultState = {
    openNavDrawer: false,
    openSideDrawer: true,
    selectedItem: {
        title: 'Profesionales',
        subtitle: 'Información de profesionales',
        to: '/dashboard/professionals',
        icon: 'people'
    },
    message: null,
    type: 'error'
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'DASHBOARD_HANDLE_NAV_DRAWER':
            return Object.assign({}, state, {
                openNavDrawer: !state.openNavDrawer
            });

        case 'DASHBOARD_HANDLE_SIDE_DRAWER':
            return Object.assign({}, state, {
                openSideDrawer: !state.openSideDrawer
            });

        case 'DASHBOARD_SELECTED_ITEM':
            return Object.assign({}, state, {
                selectedItem: action.payload.selectedItem
            });
        case 'DASHBOARD_SET_SUCCESS':
            return Object.assign({}, state, {
                type: 'success',
                message: action.payload.message
            });
        case 'DASHBOARD_SET_ERROR':
            return Object.assign({}, state, {
                type: 'error',
                message: action.payload.error
            });

        case 'DASHBOARD_CLEAR_MESSAGE':
            return Object.assign({}, state, {
                message: null
            });

        default:
            return state;
    }
}
