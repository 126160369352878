import React from "react";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import { useTheme } from '@material-ui/styles';
import { useMediaQuery, Typography, Box } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import { useHistory } from "react-router-dom";

export default function ProfessionalList(props) {
  const { data, titles } = props;

  const theme = useTheme();
  const history = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const columns = [
    {
      name: "index",
      label: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val) => {
          return (
            <Typography variant="inherit">
              {val}
            </Typography>
          )
        },
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        filterType: "textField",
        filter: false,
        sort: false,
        customBodyRender: (val) => {
          return (
            <Typography variant="inherit">
              {val}
            </Typography>
          )
        },
      },
    },
    {
      name: "speciality",
      label: "Especialidad",
      options: {
        filterType: "multiselect",
        filter: true,
        sort: false,
        customBodyRender: (val) => {
            return (val ? (
              <Typography variant="inherit">
                {val}
              </Typography>
            ) : (
              <Typography variant="inherit">
                Sin Especialidad
              </Typography>
            ))
        },
      },
    },
    {
      name: "status",
      label: "Estado",
      options: {
        filterType: "textField",
        sort: false,
        customBodyRender: (val) => {
            return (
              <Chip
                size="small"
                label={val === 1 ? "Pendiente de activación" : val === 2 ? "Activo" : "Desvinculado"}
                color={val === 1 ? "secondary" : val === 2 ? "primary" : "default"}
              />
            )
        }
      },
    },
  ];

  const options = {
    responsive: "stacked",
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    search: false,
    selectableRows: 'single',
    // textLabels: TextLabelsTable,
    // selectableRowsHeader: false,
    // disableToolbarSelect: true,
    fixedHeaderOptions: {
        yAxis: false,
        xAxis: false,
    },
    selectableRows: false,
    onRowClick: (rowData, { dataIndex, rowIndex }) => {
        history.push(`/dashboard/profesionales/${props.data[dataIndex].id}`)
    },
  };

  return (    
    <MUIDataTable
      data={data}
      columns={columns}
      options={options}
    />
  );
}
