import React, { PureComponent } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { ReportsActions, ProfessionalsActions } from "redux/actions";
import ConsultationList from "components/ConsultationList";
import { Container, CircularProgress, Grid, Button, Box, Typography } from "@material-ui/core";
import { AnHeaderBar } from "components/an";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,  
  DatePicker
} from "@material-ui/pickers";
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import DateFnsUtils from "@date-io/date-fns";
import { es } from 'date-fns/locale'

class ReportsScreen extends PureComponent {
    constructor(props) {
    super(props);
    this.state = {
        defaultProfessional: {},
        defaultFrom: new Date(new Date() - 1 * 86400000),
        defaultTo: new Date(),
        disabledSearchButton: false,
    };
    this.fixedHeightPaper = clsx(
        this.props.classes.paper,
        this.props.classes.fixedHeight
    );
    }

    componentDidMount() {
    Promise.resolve(this.props.fetchProfessionals()).then(
        this.setState({
        defaultProfessional: this.props.professionalsList.find(
            (professional) => professional.id === this.props.professionalId
        ),
        })
    );

    this.props.searchConsultations(
        this.props.professionalId,
        this.state.defaultFrom,
        this.state.defaultTo,
        this.props.page,
        this.props.pageCount
    );
    }

    componentDidUpdate(prevProps, prevState) {
    if (
        (this.props.page !== null && this.props.page !== prevProps.page) ||
        (this.props.pageCount !== null &&
        this.props.pageCount !== prevProps.pageCount)
    ) {
        this.props.searchConsultations(
        this.props.professionalId,
        this.state.defaultFrom,
        this.state.defaultTo,
        this.props.page,
        this.props.pageCount
        );
    }
    }

    handleSearch = (e) => {
    e.preventDefault();
    this.props.searchConsultations(
        this.props.professionalId,
        this.state.defaultFrom,
        this.state.defaultTo,
        this.props.page,
        this.props.pageCount
    );
    };

    onProfessionalChange = (event, values) => {
    this.setState({ defaultProfessional: values });
    if (!!values) {
        this.props.setSelectedProfessional(values.id);
    } else {
        this.props.setSelectedProfessional('');
    }
    };

    onFromChange = (date) => {
    if(!!date) { 
        this.setState({ defaultFrom: startOfDay(date) });
    } else {
        this.setState({ defaultFrom: null})
    } 
    };

    onToChange = (date) => {
    if(!!date) {     
        this.setState({ defaultTo: endOfDay(date) });
    }
    else {
        this.setState({ defaultTo: null})
    }
    };

    onErrorDate = (error) => {    
    this.setState({disabledSearchButton: error || !this.state.defaultFrom || !this.state.defaultTo })
    }
      render() {
    const {
      classes,
      consultationsList,
      loading,
    } = this.props;

    return (
      <div className={classes.root}>
        <main className={classes.content}>
          <Container maxWidth={false} className={classes.container}>
            <AnHeaderBar primary={true} title="reportes" />
            {loading ? (
              <Box align="center" p={10}>
                <CircularProgress size={40} />
                <Box mt={3}>
                    <Typography>
                        Cargando ...
                    </Typography>
                </Box>
            </Box>
            ) : !this.props.isEmpty ? (
              <div>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={4} lg={4}>
                    <Autocomplete
                      clearOnBlur
                      id="combo-box-professional"
                      onChange={this.onProfessionalChange}
                      value={this.state.defaultProfessional}
                      options={this.props.professionalsList}
                      getOptionLabel={(option) =>
                        option.name
                      }
                      style={{ marginTop: 8 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Seleccione profesional"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          <span>
                            {option.name}
                          </span>
                        </React.Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} lg={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                      <DatePicker
                        margin="normal"
                        id="date-picker-from"
                        label="Seleccione fecha desde"
                        format="dd/MM/yyyy"
                        value={this.state.defaultFrom}
                        onChange={this.onFromChange}
                        onError={this.onErrorDate}
                        KeyboardButtonProps={{
                          "aria-label": "fecha desde",
                        }}
                        inputVariant="outlined"
                        style={{ width: "100%" }}                        
                        maxDate={this.state.defaultTo}
                        maxDateMessage='La fecha no debe ser posterior a la fecha hasta'
                        minDateMessage='La fecha no debe ser anterior a la fecha mínima'
                        okLabel='Aceptar'
                        todayLabel='Hoy'
                        clearLabel='Limpiar'
                        cancelLabel='Cancelar'
                        invalidLabel='Desconocida'
                        invalidDateMessage='Formato de fecha inválido'
                        required
                        autoOk
                        showTodayButton
                        disableFuture
                        clearable={false}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        margin="normal"
                        id="date-picker-to"
                        label="Seleccione fecha hasta"
                        format="dd/MM/yyyy"
                        value={this.state.defaultTo}
                        onChange={this.onToChange}
                        onError={this.onErrorDate}
                        KeyboardButtonProps={{
                          "aria-label": "fecha hasta",
                        }}
                        inputVariant="outlined"
                        style={{ width: "100%" }}
                        minDate={this.state.defaultFrom}
                        minDateMessage='La fecha no debe ser anterior a la fecha desde'
                        maxDateMessage='La fecha no debe ser posterior a hoy'                      
                        okLabel='Aceptar'
                        todayLabel='Hoy'
                        clearLabel='Limpiar'
                        cancelLabel='Cancelar'
                        invalidLabel='Desconocida'
                        invalidDateMessage='Formato de fecha inválido'
                        required
                        autoOk
                        showTodayButton
                        disableFuture
                        clearable={false}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: 0, }}>
                    <Button
                      disabled={this.state.disabledSearchButton}
                      type="submit"
                      variant="outlined"
                      color="secondary"
                      onClick={this.handleSearch}
                      fullWidth
                    >
                      Buscar consultas
                    </Button>
                  </Grid>
                </Grid>

                <div className={classes.container}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  ) : (
                      <ConsultationList data={consultationsList} />
                    )}
                </div>
              </div>
            ) : (
                  <Alert severity="info">
                    No se han encontrado resultados con los filtros especificados
                  </Alert>
                )}
          </Container>
        </main>
      </div>
    );
  }
}

// class ReportsScreen extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       defaultProfessional: {},
//       defaultFrom: new Date(new Date() - 1 * 86400000),
//       defaultTo: new Date(),
//       disabledSearchButton: false,
//     };
//     this.fixedHeightPaper = clsx(
//       this.props.classes.paper,
//       this.props.classes.fixedHeight
//     );
//   }

//   componentDidMount() {
//     Promise.resolve(this.props.fetchProfessionals()).then(
//       this.setState({
//         defaultProfessional: this.props.professionalsList.find(
//           (professional) => professional.id === this.props.professionalId
//         ),
//       })
//     );

//     this.props.searchConsultations(
//       this.props.professionalId,
//       null,
//       null,
//       this.props.page,
//       this.props.pageCount
//     );
//   }

//   componentDidUpdate(prevProps, prevState) {
//     if (
//       (this.props.page !== null && this.props.page !== prevProps.page) ||
//       (this.props.pageCount !== null &&
//         this.props.pageCount !== prevProps.pageCount)
//     ) {
//       this.props.searchConsultations(
//         this.props.professionalId,
//         this.state.defaultFrom,
//         this.state.defaultTo,
//         this.props.page,
//         this.props.pageCount
//       );
//     }
//   }

//   handleSearch = (e) => {
//     e.preventDefault();
//     this.props.searchConsultations(
//       this.props.professionalId,
//       this.state.defaultFrom,
//       this.state.defaultTo,
//       this.props.page,
//       this.props.pageCount
//     );
//   };

//   onProfessionalChange = (event, values) => {
//     this.setState({ defaultProfessional: values });
//     if (!!values) {
//       this.props.setSelectedProfessional(values.id);
//     } else {
//       this.props.setSelectedProfessional('');
//     }
//   };

//   onFromChange = (date) => {
//     if(!!date) {     
//       this.setState({ defaultFrom: startOfDay(date) });
//     } else {
//       this.setState({ defaultFrom: null})
//     } 
//   };

//   onToChange = (date) => {
//     if(!!date) {     
//       this.setState({ defaultTo: endOfDay(date) });
//     }
//     else {
//       this.setState({ defaultTo: null})
//     }
//   };

//   onErrorDate = (error) => {    
//     this.setState({disabledSearchButton: error || !this.state.defaultFrom || !this.state.defaultTo })
//   }

//   render() {
//     const {
//       classes,
//       consultationsList,
//       loading,
//     } = this.props;

//     return (
//       <div className={classes.root}>
//         <main className={classes.content}>
//           <Container maxWidth={false} className={classes.container}>
//             <AnHeaderBar primary={true} title="reportes" />
//             {loading ? (
//               <div className={classes.loadingComp}>
//                 <CircularProgress size={25} color="secondary" />
//               </div>
//             ) : !this.props.isEmpty ? (
//               <div>
//                 <Grid
//                   container
//                   spacing={3}
//                   direction="row"
//                   justify="center"
//                   alignItems="center"
//                 >
//                   <Grid item xs={12} sm={6} lg={3}>
//                     <Autocomplete
//                       clearOnBlur
//                       id="combo-box-professional"
//                       onChange={this.onProfessionalChange}
//                       value={this.state.defaultProfessional}
//                       options={this.props.professionalsList}
//                       getOptionLabel={(option) =>
//                         option.apellido + " " + option.nombre
//                       }
//                       style={{ marginTop: 8 }}
//                       renderInput={(params) => (
//                         <TextField
//                           {...params}
//                           label="Seleccione profesional"
//                           variant="outlined"
//                           InputLabelProps={{
//                             shrink: true,
//                           }}
//                         />
//                       )}
//                       renderOption={(option) => (
//                         <React.Fragment>
//                           <span>
//                             {option.apellido} {option.nombre}
//                           </span>
//                         </React.Fragment>
//                       )}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6} lg={3}>
//                     <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
//                       <DatePicker
//                         margin="normal"
//                         id="date-picker-from"
//                         label="Seleccione fecha desde"
//                         format="dd/MM/yyyy"
//                         value={this.state.defaultFrom}
//                         onChange={this.onFromChange}
//                         onError={this.onErrorDate}
//                         KeyboardButtonProps={{
//                           "aria-label": "fecha desde",
//                         }}
//                         inputVariant="outlined"
//                         style={{ width: "100%" }}                        
//                         maxDate={this.state.defaultTo}
//                         maxDateMessage='La fecha no debe ser posterior a la fecha hasta'
//                         minDateMessage='La fecha no debe ser anterior a la fecha mínima'
//                         okLabel='Aceptar'
//                         todayLabel='Hoy'
//                         clearLabel='Limpiar'
//                         cancelLabel='Cancelar'
//                         invalidLabel='Desconocida'
//                         invalidDateMessage='Formato de fecha inválido'
//                         required
//                         autoOk
//                         showTodayButton
//                         disableFuture
//                         clearable={false}
//                       />
//                     </MuiPickersUtilsProvider>
//                   </Grid>
//                   <Grid item xs={12} sm={6} lg={3}>
//                     <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                       <DatePicker
//                         margin="normal"
//                         id="date-picker-to"
//                         label="Seleccione fecha hasta"
//                         format="dd/MM/yyyy"
//                         value={this.state.defaultTo}
//                         onChange={this.onToChange}
//                         onError={this.onErrorDate}
//                         KeyboardButtonProps={{
//                           "aria-label": "fecha hasta",
//                         }}
//                         inputVariant="outlined"
//                         style={{ width: "100%" }}
//                         minDate={this.state.defaultFrom}
//                         minDateMessage='La fecha no debe ser anterior a la fecha desde'
//                         maxDateMessage='La fecha no debe ser posterior a hoy'                      
//                         okLabel='Aceptar'
//                         todayLabel='Hoy'
//                         clearLabel='Limpiar'
//                         cancelLabel='Cancelar'
//                         invalidLabel='Desconocida'
//                         invalidDateMessage='Formato de fecha inválido'
//                         required
//                         autoOk
//                         showTodayButton
//                         disableFuture
//                         clearable={false}
//                       />
//                     </MuiPickersUtilsProvider>
//                   </Grid>
//                   <Grid item xs={12} sm={6} lg={3}>
//                     <Button
//                       disabled={this.state.disabledSearchButton}
//                       type="submit"
//                       variant="outlined"
//                       color="secondary"
//                       onClick={this.handleSearch}
//                     >
//                       Buscar consultas
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 <div className={classes.container}>
//                   {loading ? (
//                     <CircularProgress
//                       size={24}
//                       style={{ marginLeft: 15, position: "relative", top: 4 }}
//                     />
//                   ) : (
//                       <ConsultationList data={consultationsList} />
//                     )}
//                 </div>
//               </div>
//             ) : (
//                   <Alert severity="info">
//                     No se han encontrado resultados con los filtros especificados
//                   </Alert>
//                 )}
//           </Container>
//         </main>
//       </div>
//     );
//   }
// }

const mapStateToProps = (state) => ({
  consultationsList: state.reports.consultationsList,
  totalItems: state.reports.totalItems,
  professionalsList: state.professionals.professionals,
  loading: state.reports.loading,
  professionalId: state.reports.professionalId,
  page: state.reports.page,
  pageCount: state.reports.pageCount,
  error: state.professionals.error,
  success: state.professionals.success,
});

const mapDispatchToProps = (dispatch) => ({
  searchConsultations: (id, from, to, page, pageCount) =>
    dispatch(ReportsActions.searchConsultations(id, from, to, page, pageCount)),
  setSelectedProfessional: (id) =>
    dispatch(ReportsActions.setProfessionalId(id)),
  fetchProfessionals: () => dispatch(ProfessionalsActions.fetchProfessionals()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsScreen);
