const drawerWidth = 240;

const styles = theme => ({
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        width: drawerWidth,
        borderRight: 'none',
        display: 'block',
        // transition: theme.transitions.create('width', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.enteringScreen,
        // }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        // transition: theme.transitions.create('width', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen,
        // }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },

    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    headerDrawer: {
        height: 64,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#23262f'
    },
    active: {
        color: '#ffffff',
        '& $icon': {
            color: '#ffffff'
        }
    },
    button: {
        padding: '10px 18px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        fontSize: 13,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    titleBtnActive: {
        color: '#ffffff'
    },
    icon: {
        color: theme.palette.icon,
        width: 22,
        height: 22,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2)
    }
})

export default styles