import React, { PureComponent } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Copyright from 'components/Copyright';
import DashboardAppBar from 'components/dashboard/DashboardAppBar';
import DashboardDrawer from 'components/dashboard/DashboardDrawer';
import { drawerListItemsPrimary, drawerListItemsSecondary } from 'util/drawer-list-items'
import DashboardRouting from 'routes/DashboardRouting'
import { DashboardActions, LoginActions } from 'redux/actions';
import { connect } from 'react-redux';
import { AnAlertBar, AnAlertBarManager } from 'components/an';

class Dashboard extends PureComponent {

    constructor(props) {
        super(props)
        this.alertRef = React.createRef();
        this.state = {
            message: null,
            type: 'error'
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.message !== prevState.message) {
            return { message: nextProps.message, type: nextProps.type };
        }
        else return null;
    }

    componentDidUpdate(nextProps) {
        if (nextProps.message !== this.props.message) {
            this.props.clearMessage();
            AnAlertBarManager.showAlertBar(this.alertRef.current, {
                message: nextProps.message,
                type: nextProps.type,
            });
        }
    }

    render() {
        const {
            classes,
            openNavDrawer,
            handleNavDrawer,
            selectItem,
            logout,
            user
        } = this.props;
        return (
            <div className={classes.root}>
                <AnAlertBar
                    ref={this.alertRef} />
                <CssBaseline />
                <DashboardAppBar
                    open={openNavDrawer}
                    onClick={handleNavDrawer}
                    handleLogout={logout}
                    user={user}
                />
                <DashboardDrawer
                    listItemsPrimary={drawerListItemsPrimary}
                    listItemsSecondary={drawerListItemsSecondary}
                    open={openNavDrawer}
                    handleNavDrawer={handleNavDrawer}
                    selectItem={selectItem}
                    user={user}
                />
                <main className={classes.content}>
                    <DashboardRouting />
                </main>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    openNavDrawer: state.dashboard.openNavDrawer,
    selectedItem: state.dashboard.selectedItem,
    type: state.dashboard.type,
    message: state.dashboard.message,
    user: state.context.user,
})

const mapDispatchToProps = dispatch => ({
    handleNavDrawer: () => dispatch(DashboardActions.handleNavDrawer()),
    selectItem: (item) => dispatch(DashboardActions.selectItem(item)),
    clearMessage: () => dispatch(DashboardActions.clearMessage()),
    logout: () => dispatch(LoginActions.logout())
})


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)