const styles = theme => ({
    root: {
        height: `calc(100% - 20)`,
    },
    container: {
        // backgroundColor: '#fff',
        // margin: 20,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 4,
    },
});


export default styles