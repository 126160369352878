
const styles = theme => ({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    cardContainer: {
        marginBottom: theme.spacing(4)
    },
    avatar: {
        height: 150,
        width: 150,
        flexShrink: 0,
        flexGrow: 0,
    },
    media: {
        margin: 30,
        width: 80
    },
    cardLoading: {
        height: 200
    }
})


export default styles