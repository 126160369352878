// @flow
import _ from 'lodash';

const defaultState: any = {
    loading: false,
    countries: [],
    languages: [],
    titles: [],
    invitationStates: [],
    specialities: []
};

/**
 *  The redux reducer for i18n.
 *
 *  @param {any} state - The current state.
 *  @param {any} action - The current action.
 */

export default function reducer(state: any = defaultState, action: any) {
    switch (action.type) {

        case 'CONFIGURATION_FETCH_INVITATION_STATE_SUCCESS':
            return Object.assign({}, state, {
                invitationStates: action.payload.invitationStates
            });

        case 'CONFIGURATION_FETCH_COUNTRIES_SUCCESS':
            return Object.assign({}, state, {
                countries: _.orderBy([].concat(
                    action.payload.countries,
                ), ['name'], ['asc']),
            });

        case 'CONFIGURATION_FETCH_LANGUAGES_SUCCESS':
            return Object.assign({}, state, {
                languages: [].concat(
                    action.payload.languages,
                ),
            });

        case 'CONFIGURATION_FETCH_TITLES_SUCCESS':
            return Object.assign({}, state, {
                titles: [].concat(
                    action.payload.titles,
                ),
            });

        case 'CONFIGURATION_FETCH_SPECIALITIES_LOADING':
            return Object.assign({}, state, {
                loading: true
            });

        case 'CONFIGURATION_FETCH_SPECIALITIES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                specialities: action.payload.specialities
            });
        default:
            return state;
    }
}
