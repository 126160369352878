import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import AuthRoute from 'util/AuthRoute';
import AuthRedirect from 'util/AuthRedirect';
import { LoginScreen, Dashboard } from 'screens';
import 'App.css';
import firebase from 'firebase/app';
import 'firebase/auth';
import Store from './redux';
import { ContextActions } from 'redux/actions';
import JWT from 'services/jwt';
//UI
import theme from './theme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
})

function App() {
    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                Store.dispatch(
                    ContextActions.setContext(JWT.parseToken(await user.getIdToken()))
                );
            } else {
                Store.dispatch(
                    ContextActions.setContext(null)
                )
            }
        })
    }, []);
  
  return (
    <MuiThemeProvider theme={theme} >
      <Router>
        <Switch>
          <Route exact path="/login">
            <LoginScreen />
          </Route>
          <AuthRoute path='/dashboard' component={Dashboard} />
          <AuthRedirect from='/*' to='/dashboard/profesionales' />
        </Switch>
      </Router>
    </MuiThemeProvider>
  )
}

export default App;
