import palette from '../palette';

export default {
    root: {
        textTransform: "initial",
        margin: `0px 0px`,
        minWidth: 0,
    },
    textColorPrimary: {
        color: palette.text.primary,
        '&$selected': {
            color: palette.text.primary,
        },
    },
}