import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import tokenProvider from 'axios-token-interceptor';
import Store from '../redux'
import { LoginActions } from 'redux/actions';
export default class RestClient {
    baseURL: string;
    instance: any;

    constructor(
        baseURL,
        secured
    ) {
        // Use instaces values or fallback to
        // environment vars.
        this.baseURL = process.env.REACT_APP_BASE_API_URL;

        // Create the axios instance
        this.instance = axios.create({
            baseURL
        });

        if (secured) {
            /**
             *  Use request interceptor to add credentials
             *  to the request
             */

            this.instance.interceptors.request.use(tokenProvider({
                getToken: () => {
                    const user = firebase.auth().currentUser;
                    if (user) {
                        return user.getIdToken().then(result => result);
                    }

                    return undefined;
                },
            }));
        }

        if (secured) {

            /**
             *  Credentials may be out of date, add response
             *  interceptor to perform refresh token flow and
             *  repeat the last request
             */

            this.instance.interceptors.response.use(undefined, (error) => {
                const { response } = error;

                if (response
                    && response.status === 401
                    && response.config
                    && response.config.headers['x-retry-request'] !== 'retry') {
                    return new Promise((resolve) => {
                        // Update Token
                        if (error.config && error.config.headers) {
                            // First we get the current user
                            const user = firebase.auth().currentUser;
                            
                            if (user) {
                                user.getIdToken(true)
                                    .then((token) => {
                                        // Mark the retry request as retry
                                        error.config.headers['x-retry-request'] = 'retry'
                                        // Change the bearer to new token
                                        error.config.headers['Authorization'] = `Bearer ${token}`

                                        // Repeat the request with the
                                        // renewed token
                                        resolve(this.instance(error.config));
                                    });
                            }
                        }
                    });
                } else if (response
                    && response.status === 401
                    && response.config
                    && response.config.headers['x-retry-request'] === 'retry') {
                    firebase.auth().signOut()
                        .then(() => {
                            Store.dispatch(LoginActions.logout())
                        });
                }

                return Promise.reject(error);
            });
        }

        // We catch request error due a request error
        // and retry it again
        this.instance.interceptors.response.use(undefined, (error) => {
            if (error.response) {
                return Promise.reject(error)
            }

            if (error.request && error.config.headers['x-retry'] !== 'retry') {
                // Add flag to register the request 
                // as retry
                error.config.headers['x-retry'] = 'retry'

                return new Promise((resolve) => {
                    resolve(this.instance(error.config));
                })
            }

            return Promise.reject(error)
        })

        // We catch request error due a request error
        // in the retry
        this.instance.interceptors.response.use(null, (error) => {
            if (error.response) {
                return Promise.reject(error);
            }

            if (error.request) {
                if (error.message && error.message.includes('timeout')) {
                    return Promise.reject(
                        new Error('NETWORK-PERF: Esto está tardando demasiado. Asegúrate de tener conexión a internet e intenta nuevamente!'),
                    );
                }

                if (error.message && error.message.includes('Network Error')) {
                    return Promise.reject(
                        new Error('NETWORK-PERF: Ups! Algo ha fallado debido a un error en la red de internet. Intenta nuevamente!'),
                    );
                }

                if (error.message && error.message.includes('Request aborted')) {
                    return Promise.reject(
                        new Error('NETWORK-PERF: Ups! Algo ha fallado debido a un error en la red de internet. Intenta nuevamente!'),
                    );
                }
            }

            return Promise.reject(error);
        });
    }
}
