import React, { PureComponent } from 'react';
import clsx from 'clsx';
import SendIcon from '@material-ui/icons/Send';
import { connect } from 'react-redux';
import {
  InvitationsActions,
  ConfigurationActions,
} from 'redux/actions'
import InvitationsList from './components/InvitationsList';
import { Link } from 'react-router-dom';
import {
  Container,
  Button,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
  Box,
  Typography,
  Icon,
} from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views';
import { AnHeaderBar } from 'components/an'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}


class ProfessionalsScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    }
    this.fixedHeightPaper = clsx(this.props.classes.paper, this.props.classes.fixedHeight);
  }

  componentDidMount() {
      this.props.fetchInvitations()
    this.props.fetchInvitationStates();
  }

  render() {
    const {
      classes,
      professionalsList,
      invitations,
      titles,
      invitationStates,
      loading
    } = this.props;
    const { value } = this.state;

    return (
        <div className={classes.root}>
            <div className={this.props.classes.container}>
                <AnHeaderBar
                    primary={true}
                    title="Invitaciones"
                />

                <Box>
                    {(this.props.loading ? (
                        <Box align="center" p={10}>
                            <CircularProgress 
                                size={40}
                            />
                            
                            <Box mt={3}>  
                                <Typography>
                                    Cargando ...
                                </Typography>
                            </Box>
                        </Box>
                ) : this.props.invitations.length === 0 ? (
                    <Box p={10} align="center" maxWidth={600} padding={3} margin="50px auto" bgcolor="#fff" borderRadius={6} border="1px solid #ddd">
                        <Icon style={{ fontSize: 60, color: '#e7e7e7', marginBottom: 10 }}>
                            send
                        </Icon>
            
                        <Typography style={{ fontWeight: 500, marginBottom: 10, maxWidth: 400 }}>
                            Puedes enviar invitaciones a los profesionales que quieres que formen
                            parte de tu organización
                        </Typography>

                        <Box align="center" mt={2} mb={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                to={'/dashboard/invitaciones/envio'}
                                component={Link}
                            >
                                Enviar invitaciones
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Box>
                        <Box align="right" mt={2} mb={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<SendIcon />}
                                to={'/dashboard/invitaciones/envio'}
                                component={Link}
                            >
                                Enviar nuevas invitaciones
                            </Button>
                        </Box>
                        
                        <Box>
                            <InvitationsList
                                data={invitations}
                                invitationStates={invitationStates}
                            />
                        </Box>
                    </Box>
                ))}
                </Box>
            </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
    invitations: state.invitations.invitations || [],
    loading: state.invitations.loading,
//   professionalsList: state.professionals.professionalsList,
//   pendingInvitations: state.professionals.pendingInvitations,
//   loading: state.professionals.loading,
//   titles: state.configuration.titles,
  invitationStates: state.configuration.invitationStates,
})

const mapDispatchToProps = dispatch => ({
  fetchInvitations: () => dispatch(InvitationsActions.fetchInvitations()),
  fetchInvitationStates: () => dispatch(ConfigurationActions.fetchInvitationStates()),
})


export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalsScreen)

