import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SendInvitation from "./components/SendInvitation";
import {
  CircularProgress,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { InvitationsActions } from 'redux/actions';
import { AnHeaderBar } from 'components/an';
import { withStyles } from '@material-ui/core/styles';

function getSteps() {
  return ["Ingresar emails", "Enviar emails"];
}

const StyledTableCell = withStyles((theme) => ({
root: {
    borderBottom: '1px solid #ccc',
},
head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    borderWidth: 0,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#f8f9fa',
    },
  },
}))(TableRow);

class InvitationsFormScreen extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      activeStep: 0,
    }
  }

  componentWillUnmount() {
    this.props.clear()
  }

  handleNext = () => {

    this.setState({
      activeStep: this.state.activeStep + 1
    })
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    })
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    })
  };

    render() {
        const { classes } = this.props;
        const { activeStep } = this.state
        const steps = getSteps();
    
        return (
            <div className={classes.root}>
                <main className={classes.content}>
                    <Container maxWidth={false} className={classes.container}>
                        <AnHeaderBar />

                        <Box mb={3}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    return (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Box>

                        <div>
                            {activeStep === steps.length ? (
                                <div>
                                    <Typography className={classes.instructions}>
                                        Todos los pasos completados!
                                    </Typography>
                                    
                                    <Button onClick={this.handleReset} className={classes.button}>
                                        Volver
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    {(this.state.activeStep === 0) &&
                                        <SendInvitation
                                            handleInput={this.props.handleInput}
                                            emailInput={this.props.emailInput}
                                            validateEmailLength={this.props.validateEmail.length}
                                        />
                                    }
                   
                                    {(this.state.activeStep === 1) && (
                                        <Box>
                                            <TableContainer component={Paper}>
                                                <Table
                                                    size="small"
                                                    aria-label="customized table"
                                                >
                                                    <TableBody>
                                                        {this.props.emailList.map((email, index) => (
                                                            <StyledTableRow
                                                                key={index}
                                                                style={{
                                                                    borderBottom:' 1px solid'
                                                                }}
                                                            >
                                                                <StyledTableCell component="th" scope="row">
                                                                    {email.email}
                                                                </StyledTableCell>
                                                                
                                                                <StyledTableCell align="center">
                                                                    <Typography
                                                                        style={{
                                                                            color: email.validate ? '#1e9d6f' : '#e83e8c',
                                                                            textAlign: 'right',
                                                                        }}
                                                                    >
                                                                        {email.validate ? 'Válido' : 'No válido'}
                                                                    </Typography>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    )}
                    
                                    {(this.state.activeStep === 1) &&
                                        <Grid container
                                            style={{
                                                backgroundColor: this.props.success ? 'rgba(166, 226, 46, 0.11)' : 'rgba(255,229,100,0.2)',
                                                borderLeftColor: this.props.success ? '#a6e22e' : '#ffe564',
                                            }}
                                            className={classes.alertMessage}
                                        >
                                            <Grid item>
                                                <Typography className={classes.instructions}>
                                                    {this.props.success ?
                                                    this.props.validateEmail.length > 1 ?
                                                        `Se enviaron ${this.props.validateEmail.length} invitaciones correctamente. Podrá visualizar el estado de las invitaciones en la página principal`
                                                        :
                                                        `Se envió ${this.props.validateEmail.length} invitación correctamente. Podrá visualizar el estado de las invitaciones en la página principal`
                                                    :
                                                    this.props.validateEmail.length > 1 ?
                                                        `Se enviarán ${this.props.validateEmail.length} invitaciones!`
                                                        :
                                                        `Se enviará ${this.props.validateEmail.length} invitación!`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                    
                                    <Box
                                        style={{
                                            marginTop: 25,
                                            marginBottom: 25,
                                            float: 'right'
                                        }}
                                    >
                                        <Button
                                            disabled={activeStep === 0 || this.props.loading}
                                            onClick={this.handleBack}
                                            className={classes.button}
                                            loading={this.props.loading}
                                        >
                                            Atrás
                                        </Button>
                     
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            loading={this.props.loading}
                                            disabled={this.props.emailList.length === 0 ||
                                            this.props.loading ||
                                            this.props.validateEmail.length === 0}
                                            onClick={activeStep === steps.length - 1 ? this.props.sendInvitations : this.handleNext}
                                            className={classes.button}
                                        >
                                            {activeStep === steps.length - 1 ?
                                                this.props.loading ? ( 
                                                    <CircularProgress color='secondary' size={20} className={this.props.classes.progress} />
                                                ) : "Enviar" : "Siguiente"}
                                        </Button>
                                    </Box>
                  </div>
                )}
            </div>
          </Container>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  emailInput: state.invitations.emailInput,
  validateEmail: state.invitations.validateEmail,
  emailList: state.invitations.emailList,
  loading: state.invitations.loading,
  success: state.invitations.success
})

const mapDispatchToProps = dispatch => ({
  handleInput: (name, value) => dispatch(InvitationsActions.handleInput(name, value)),
  sendInvitations: () => dispatch(InvitationsActions.sendInvitations()),
  clear: () => dispatch(InvitationsActions.clear()),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvitationsFormScreen)