const styles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  ulPaper: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: '4px 0',
    width: '100%',
    justifyContent: 'flex-start',
  },
  chipLabel: {
      width: '100%',
  },
  loadingComp: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: 10,
    justifyContent: 'flex-end'
  }
});

export default styles;
