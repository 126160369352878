const styles = (theme) => ({
  root: {
    position:'relative'
  },
  cardContent: {
      root: {
          position: 'relative'
      }
  },
  details: {
    display: "flex",
  },
  avatar: {
    height: 100,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
  },
});

export default styles;
