import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
    ListItem,
    Icon,
    Button,
    Drawer,
    Typography,
    List,
    Box,
    Avatar,
    CircularProgress
} from '@material-ui/core';
import {
    useLocation,
} from "react-router-dom";
import { useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { BorderRight } from '@material-ui/icons';



function DashboardDrawer(props) {
    const { classes,
        listItemsPrimary = [],
        open = false,
        handleNavDrawer,
        selectItem,
        user } = props
    const theme = useTheme();
    const location = useLocation();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });


    const MainListItem = ({ item }) => {
        return (
            <ListItem
                style={{
                    display: 'flex',
                    padding: 5,
                    borderBottom: '1px solid #5f677f54'
                }}
                disableGutters
            >
                <Button
                    className={(location.pathname.includes(item.to)) ?
                        clsx(classes.button, classes.active) : classes.button}
                    component={Link}
                    to={item.to}
                    onClick={isDesktop ?
                        () => selectItem(item)
                        :
                        () => {
                            selectItem(item)
                            handleNavDrawer()
                        }
                    }
                >
                    <div className={classes.icon}>
                        <Icon>{item.icon}</Icon>
                    </div>

                    <Typography
                        className={(location.pathname.includes(item.to)) ?
                            classes.titleBtnActive : null}
                        variant='subtitle2'>
                        {open && item.title}
                    </Typography>
                </Button>
            </ListItem >
        )
    }

    const renderMainListItems = (listItems) => (
        listItems.map((item, index) => (
            <div key={index}>
                <MainListItem
                    item={item} />
            </div>))
    )

    return (
        <Drawer
            variant={isDesktop ? "permanent" : "temporary"}
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
            onClose={handleNavDrawer}
        >
            <Box className={classes.headerDrawer}>
                {(!open && (
                    (!!user.institucionlogo ?

                        <Avatar
                            alt="institucion logo"
                            src={user.institucionlogo}
                            className={classes.accountAvatar}
                        />
                        :
                        <CircularProgress
                            size={20}
                            color='secondary' />
                    )
                ))}

                {(open && (
                    <Typography variant="h4" style={{ color: '#fff' }}>
                        {user.institucionnombre}
                    </Typography>
                ))}
            </Box>

            <Box
                display="flex"
                flexDirection="row"
                p={1.25}
                mt={1}
                mb={1}
                height={62}
                justifyContent={!open ? "center" : "flex-start"}
            >
                <Avatar
                    alt="profile picture"
                    src="https://miro.medium.com/max/525/1*lyyXmbeoK5JiIBNCnzzjjg.png"
                    className={classes.accountAvatar}
                />

                {(open && (
                    <Box ml={2} align="start">
                        <Typography style={{ color: '#fff' }}>
                            {`${user.usuarionombre} ${user.usuarioapellido}`}
                        </Typography>

                        <Typography style={{ color: '#ffffff50', fontSize: 13 }}>
                            Administrador
                        </Typography>
                    </Box>
                ))}
            </Box>

            <Box
                p={1.25}
                bgcolor="#a6b1d0"
                align={open ? "left" : "center"}
            >
                <Typography style={{ color: theme.palette.primary, fontWeight: 500 }}>
                    MENU
                </Typography>
            </Box>

            <List
                classes={{
                    root: clsx({ backgroundColor: 'red' })
                }}
            >
                {renderMainListItems(listItemsPrimary)}
            </List>
        </Drawer>
    )
}


export default DashboardDrawer