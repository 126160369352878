import React from "react";
import {
  TextField,
  Typography,
  Paper,
  Tabs,
  Tab,
  Grid,
  Box,
  CircularProgress,
} from "@material-ui/core";
import AttachFile from "@material-ui/icons/AttachFile";
import SwipeableViews from 'react-swipeable-views';
import Dropzone from 'react-dropzone'
import readXlsxFile from 'read-excel-file'

const schema = {
  'Email': {
    prop: 'email',
    type: 'Email'
  },
  'email': {
    prop: 'email',
    type: 'Email'
  },
  'Correo': {
    prop: 'email',
    type: 'Email'
  },
  'correo': {
    prop: 'email',
    type: 'Email'
  },
  'correo electronico': {
    prop: 'email',
    type: 'Email'
  },
  'correo electrónico': {
    prop: 'email',
    type: 'Email'
  },
  'Correo electrnico': {
    prop: 'email',
    type: 'Email'
  },

}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

class SendInvitation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      excelLoading: false,
      excelList: false
    }
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue
    });
  };

  handleChangeIndex = (index) => {
    this.setState({
      value: index
    });
  };

  handleOnDropFile = (acceptedFiles) => {
    this.setState({
      excelLoading: true
    })
    readXlsxFile(acceptedFiles[0], { schema })
      .then(({ rows }) => {
        this.setState({
          excelLoading: false,
          excelList: true
        })
        let dataFormated = rows.map(item => item.email)
        this.props.handleInput('excelEmailInput', dataFormated)
      })
  }

  render() {
    const { value, excelList } = this.state;
    const { classes, emailInput, validateEmailLength } = this.props
    
    return (
        <Grid>
            <Tabs
                value={value}
                onChange={this.handleChange}
                textColor="primary"
            >
                <Tab label="Modo simple" {...a11yProps(0)} />
                <Tab label="Modo masivo" {...a11yProps(1)} />
            </Tabs>
        
            <SwipeableViews
                axis='x'
                index={value}
                onChangeIndex={this.handleChangeIndex}
            >
                <TabPanel value={value} index={0} >
                    <Paper style={{ padding: 30, borderRadius: 0 }}> 
                        <TextField
                            label="Emails"
                            id="text-email"
                            size="small"
                            margin="normal"
                            name="emailInput"
                            fullWidth
                            multiline
                            rows={4}
                            rowsMax={10}
                            onChange={(e) => this.props.handleInput(e.target.name, e.target.value)}
                            value={emailInput}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <Box mt={1}>
                            <Typography>
                                Ingresar el o los emails de los profesionales a los que desean invitar separados por espacios o saltos de línea
                            </Typography>
                        </Box>
                    </Paper>
                </TabPanel>
                
                <TabPanel value={value} index={1} >
                    <Paper component="form" style={{ padding: 30, borderRadius: 0 }}>
                        <Dropzone multiple={false} onDrop={this.handleOnDropFile}>
                            {({ getRootProps, getInputProps, isDragActive }) => (
                                <section>
                                    <div {...getRootProps()}
                                    style={{
                                        padding: 30,
                                        border: '2px dashed #ced4da',
                                        borderRadius: 6,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <input {...getInputProps()} />
                                    {
                                        this.state.excelLoading ?
                                        <CircularProgress size={25} color='secondary' />
                                        :
                                        isDragActive ?
                                            <Typography variant='h6' component='h6'>Ingresar archivo aquí ...</Typography> :
                                            <Typography variant='h6' component='h6'>Arrastrar archivo aquí, o click para seleccionar archivo</Typography>
                                    }                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Paper>
                </TabPanel>
            </SwipeableViews>
        
        {excelList &&
          <Grid container
            style={{
              backgroundColor: 'rgba(166, 226, 46, 0.11)',
              borderLeftColor: '#a6e22e',
            }}
            className={classes.alertMessage}>
            <Grid item>
              <Typography className={classes.instructions}>
                {`Hay disponibles ${validateEmailLength} correos electrónicos válidos para enviar. Presionar 'Siguiente'`}
              </Typography>
            </Grid>
          </Grid>
        }
      </Grid >

    )
  }
}


export default SendInvitation