
const styles = theme => ({
    root: {
       height: '100%'
    },
    content: {
        flexGrow: 1,
        overflow: 'auto',
        height: '100vh',
        paddingTop: 64,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeight: {
        height: 240,
    },
});


export default styles