import InstitucionRestClient from "http/resources/InstitucionRestClient"
import ErrorManager, { ApiNonFatalError } from 'services/error-manager';
import { PaymentMethodLinkStatus } from 'constants/index';

function getAvailableGateway() {
    return async (dispatch) => {
        dispatch({
            type: 'PAYMENT_GET_AVAILABLE_GATEWAY_START',
        });

        new InstitucionRestClient(true).getAvailableGateway()
            .then(response => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    );
                }
                dispatch({
                    type: 'PAYMENT_GET_AVAILABLE_GATEWAY_SUCCESS',
                    payload: {
                        gateway: response.data.value.gateway,
                        activeAccountGateway: response.data.value.cuenta
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: 'PAYMENT_GET_AVAILABLE_GATEWAY_FAILURE',
                });
                dispatch({
                    type: 'DASHBOARD_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            });
    }

}

/**
 *  Start sync gateway config
 */
function startGatewayConfig(gateway) {
    return dispatch => {
        dispatch({
            type: 'PAYMENT_GATEWAYS_INIT_START',
        });
        new InstitucionRestClient(true).initAuthorizationRequestGateway({
            gateway
        })
            .then(response => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    );
                }
                dispatch({
                    type: 'PAYMENT_GATEWAYS_INIT_SUCCESS',
                    payload: {
                        authRequestId: response.data.value.authRequestId,
                        webviewURL: response.data.value.url,
                        webviewTitle: response.data.value.titulo,
                        webviewSubtitle: response.data.value.subtitulo,
                    },
                });
            })
            .catch(error => {
                dispatch({
                    type: 'DASHBOARD_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }
}

/**
 *  Check gateway
 */
function checkAuthorizationRequestGateway() {
    return async (dispatch, getState) => {

        dispatch({
            type: 'PAYMENT_GATEWAYS_CHECK_START',
        });

        const { authRequestId } = getState().gateway;
        let counter = 0;
        const interval = setInterval(() => {
            counter += 1;
            if (counter < 20) {
                new InstitucionRestClient(true).checkAuthorizationRequestGateway({
                    authRequestId
                })
                    .then((response) => {
                        if (response.data.isFaulted) {
                            return Promise.reject(
                                new ApiNonFatalError(response),
                            );
                        }
                        if (response.data.value === PaymentMethodLinkStatus.UNDEFINED) return;
                        if (response.data.value === PaymentMethodLinkStatus.PROCESSING) return;
                        if (response.data.value === PaymentMethodLinkStatus.AUTHORIZED) {
                            dispatch({
                                type: 'PAYMENT_GATEWAYS_CHECK_SUCCESS',
                                payload: {
                                    status: response.data.value,
                                },
                            });
                        } else {
                            dispatch({
                                type: 'PAYMENT_GATEWAYS_CHECK_FAILURE',
                                payload: {
                                    status: response.data.value,
                                },
                            });
                        }

                        clearInterval(interval);
                    }).catch((error) => {
                        clearInterval(interval);
                        dispatch({
                            type: 'PAYMENT_GATEWAYS_CHECK_FAILURE',
                            payload: {
                                status: PaymentMethodLinkStatus.INTERNAL_ERROR,
                            },
                        });
                        dispatch({
                            type: 'DASHBOARD_SET_ERROR',
                            payload: {
                                error: ErrorManager.toMessage(error),
                            }
                        });
                    })
            } else {
                clearInterval(interval);
                dispatch({
                    type: 'PAYMENT_GATEWAYS_CHECK_FAILURE',
                    payload: {
                        status: PaymentMethodLinkStatus.ERROR,
                    },
                })
            }
        }, 2000);
    };
}

/**
 *  Sync the gateway
 */
function syncGateway() {
    return async (dispatch, getState) => {

        dispatch({
            type: 'PAYMENT_GATEWAYS_SYNC_START',
        });
        const { authRequestId } = getState().gateway;

        new InstitucionRestClient(true).syncGateway({
            authRequestId
        })
            .then((response) => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response),
                    );
                }

                dispatch({
                    type: 'PAYMENT_GATEWAYS_SYNC_SUCCESS',
                });
            })
            .catch((error) => {
                dispatch({
                    type: 'PAYMENT_GATEWAYS_SYNC_FAILURE',
                });
                dispatch({
                    type: 'DASHBOARD_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            });
    };
}

/**
 *  Delete the gateway
 */
function deleteGateway() {
    return async (dispatch) => {

        new InstitucionRestClient(true).deleteGateway()
            .then((response) => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response),
                    );
                }
                dispatch({
                    type: 'PAYMENT_GATEWAYS_DELETE_SUCCESS',
                });
            })
            .catch((error) => {
                dispatch({
                    type: 'DASHBOARD_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            });
    };
}

function setError(message) {
    return {
        type: 'DASHBOARD_SET_ERROR',
        payload: {
            error: message,
        }
    }
}

export const GatewayActions = {
    startGatewayConfig,
    checkAuthorizationRequestGateway,
    syncGateway,
    getAvailableGateway,
    deleteGateway,
    setError
}