import React from 'react';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import {
    ListItem,
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    Icon,
    Avatar,
    ListItemText,
    Box,
    Hidden,
    Menu,
    MenuItem
} from '@material-ui/core';


const DashboardAppBar = ({ classes, open = false, handleLogout, onClick, user }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <AppBar color="white" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <Box display='flex' flexDirection='row'>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={onClick}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                </Box>
                
                <Box display="flex" flexDirection="row">
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={onClick}
                    >
                        <Icon>notifications</Icon>
                    </IconButton>
                    
                    <ListItem
                        disableGutters
                        className={clsx(classes.iconListItem, classes.smBordered)}
                    >
                        <Avatar
                            alt="profile picture"
                            src="https://miro.medium.com/max/525/1*lyyXmbeoK5JiIBNCnzzjjg.png"
                            onClick={handleClick}
                            className={classes.accountAvatar}
                        />
                        
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleLogout}>
                                <Icon>exit_to_app</Icon>
                                <Typography>
                                    Salir
                                </Typography>
                            </MenuItem>
                        </Menu>
                        
                        <ListItemText
                            className={classes.username}
                            primary={
                                <Typography
                                    color="inherit"
                                    component="h6"
                                    variant="body2"
                                >
                                    {`${user.usuarionombre} ${user.usuarioapellido}`}
                                </Typography>
                            }
                        />
                    </ListItem>
                </Box>
            </Toolbar>
        </AppBar >)
}


export default DashboardAppBar