import React, { PureComponent } from 'react';
import {
    Container,
    Grid,
    Button,
    Paper,
    TableContainer,
    TableCell,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Typography,
    Box,
    Icon
} from '@material-ui/core';
import { connect } from 'react-redux';
import { AnHeaderBar } from 'components/an';
import AddIcon from '@material-ui/icons/Add';
import DirectoryList from './components/DirectoryList';
import { DirectoryActions, ConfigurationActions } from 'redux/actions';
import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";

const columns = [
    {
      name: "nombre",
      label: "Especialidad",
      options: {
        filterType: "textField",
        filter: false,
        sort: false,
        customBodyRender: (val) => {
          return (
            <Typography variant="inherit">
              {val}
            </Typography>
          )
        },
      },
    },
  ];

  const options = {
    responsive: "stacked",
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    search: false,
    selectableRows: 'single',
    fixedHeaderOptions: {
        yAxis: false,
        xAxis: false,
    },
    selectableRows: false,
    onRowClick: (rowData, { dataIndex, rowIndex }) => {
        console.warn('dddd', rowData)
        // history.push(`/dashboard/profesionales/${props.data[dataIndex].id}`)
    },
  }
class DirectoryScreen extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            openDialog: false
        }
    }

    componentDidMount() {
        this.props.fetchSpecialities()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.create.success && (this.props.create.success !== prevProps.create.success)) {
            this.handleClose()
            this.props.fetchSpecialities()
        }
    }

    handleSubmit = () => {
        this.props.submitNewSpeciality()
    }

    handleClose = () => {
        this.setState({
            openDialog: false
        })
    }

    handleOpen = () => {
        this.setState({
            openDialog: true
        })
    }
    render() {
        const {
            classes,
            specialities
        } = this.props;

        return (
            <div className={classes.root}>
                <div className={this.props.classes.container}>
                    <AnHeaderBar
                        primary={true}
                        title="Cartilla"
                    />

                    <Box>
                        {(this.props.fetch.loading ? (
                            <Box align="center" p={10}>
                                <CircularProgress 
                                    size={40}
                                />
                                
                                <Box mt={3}>  
                                    <Typography>
                                        Cargando ...
                                    </Typography>
                                </Box>
                            </Box>
                        ) : this.props.fetch.error ? (
                            <Box bgcolor="#fff" p={10} align="center">
                                <Typography>
                                    Ha ocurrido un error al intentar obtener la cartilla
                                </Typography>
                            </Box>
                        ) : this.props.specialities.length === 0 ? (
                            <Box p={10} align="center" maxWidth={600} padding={3} margin="50px auto" bgcolor="#fff" borderRadius={6} border="1px solid #ddd">
                                <Icon style={{ fontSize: 60, color: '#e7e7e7', marginBottom: 10 }}>
                                    assignment
                                </Icon>
                    
                                <Typography style={{ fontWeight: 500, marginBottom: 10, maxWidth: 400 }}>
                                    Aún no hay especialidades creadas. Comienza a armar las especialidades de
                                    cartilla de profesionales.
                                </Typography>
        
                                <Box align="center" mt={2} mb={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleOpen}
                                    >
                                        Crear especialidad
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Box>
                                <Box align="right" mt={2} mb={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleOpen}
                                        endIcon={
                                            <AddIcon />
                                        }
                                    >
                                        Agregar nueva especialidad
                                    </Button>
                                </Box>
                                <DirectoryList
                                    data={specialities}
                                />
                            </Box>
                        ))}
                    </Box>
                </div>

                <Dialog
                    fullWidth
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                >
                    <DialogTitle>
                        Agregar nueva especialidad
                    </DialogTitle>
                    
                     <DialogContent>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                            fullWidth
                            autoFocus
                            id="outlined-basic"
                            label="Ingresar nueva especialidad"
                            variant="filled"
                            name="speciality"
                            value={this.props.create.speciality}
                            onChange={(e) => this.props.setNewSpecialityName(e.target.value)}
                            error={!!this.props.create.error}
                        />

                        {(!!this.props.create.error && (
                            <Box
                                mt={0}
                                p={1}
                                bgcolor="error.ultraLight"
                                borderColor="error.dark"
                                borderRadius={4}
                                align="center"
                            >
                                <Typography variant="caption" color="error">{this.props.create.error}</Typography>
                            </Box>
                        ))}
                    </DialogContent>
                    
                    <DialogActions>
                        <Button onClick={this.handleClose}>
                            Cancelar
                        </Button>
                        
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={
                                !this.props.create.speciality
                                    || this.props.create.loading
                            }
                            endIcon={(
                                <AddIcon />
                            )}
                            align='flex-end'
                            onClick={this.handleSubmit}
                        >
                            {this.props.create.loading ? (
                                <CircularProgress
                                    color='secondary'
                                    size={25}
                                />
                            ) : 'Agregar'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    fetch: state.directory.fetch,
    create: state.directory.create,
    specialities: state.directory.specialities,
})

const mapDispatchToProps = dispatch => ({
    fetchSpecialities: () => dispatch(DirectoryActions.fetchSpecialities()),
    submitNewSpeciality: () => dispatch(DirectoryActions.submitNewSpeciality()),
    setNewSpecialityName: (name) => dispatch(DirectoryActions.setNewSpecialityName(name)),
})




export default connect(mapStateToProps, mapDispatchToProps)(DirectoryScreen)

