import InstitucionRestClient from "http/resources/InstitucionRestClient"
import ErrorManager, { ApiNonFatalError } from 'services/error-manager';
import { TitlesMap } from '../../constants'

function fetchProfessionals() {
    return dispatch => {
        dispatch({
            type: "PROFESSIONALS_FETCH_LINKED_PROFESSIONAL_START",
        })

        new InstitucionRestClient(true).getProfessionalsList()
            .then((response) => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    );
                }

                dispatch({
                    type: "PROFESSIONALS_FETCH_LINKED_PROFESSIONAL_SUCCESS",
                    payload: {
                        professionals: response.data.value.listaMedicos.map((item, index) => ({
                            index: index + 1,
                            id: item.id,
                            name: `${TitlesMap[item.titulo]} ${item.nombre} ${item.apellido}`,
                            speciality: item.especialidad,
                            status: item.estado,
                        })),
                    }
                })
            })

            .catch(error => {
                dispatch({
                    type: "PROFESSIONALS_FETCH_LINKED_PROFESSIONAL_FAILURE",
                })
                
                dispatch({
                    type: 'DASHBOARD_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }
}

export const ProfessionalsActions = {
    fetchProfessionals,
}