const styles = theme => ({
    // root: {
    //     display: 'flex',
    //     width: "100%",
    // },    
    // content: {
    //     flexGrow: 1,
    //     overflow: 'auto',
    // },
    // container: {
    //     paddingTop: theme.spacing(2),
    //     paddingBottom: theme.spacing(4),
    // },
    // paper: {
    //     padding: theme.spacing(2),
    //     display: 'flex',
    //     overflow: 'auto',
    //     flexDirection: 'column',
    // },
    // fixedHeight: {
    //     height: 240,
    // },
    // toolbar: {
    //     root: {
    //         paddingLeft: theme.spacing(2),
    //         paddingRight: theme.spacing(1),
    //     },       
    //     title: {
    //         flex: "1 1 100%",
    //     },
    // }
    root: {
        height: `calc(100% - 20)`,
    },
    container: {
        // backgroundColor: '#fff',
        // margin: 20,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 4,
    },
});


export default styles