import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Card,
    Grid,
    CardContent,
    Avatar,
    Typography,
    CssBaseline,
    CardActions,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    CircularProgress,
    CardHeader,
    Box
} from '@material-ui/core';
import { AnHeaderBar } from 'components/an';
import { GatewayActions } from 'redux/actions';
import { PaymentMethodLinkStatus } from 'constants/index'

class ProfileScreen extends PureComponent {

    state = {
        open: false
    }

    componentDidMount() {
        this.props.getAvailableGateway()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.webviewURL && this.props.webviewURL !== prevProps.webviewURL) {
            window.open(this.props.webviewURL, this.props.webviewTitle, 'width=500,height=400,left=300,top=200');
            this.props.checkAuthorizationRequestGateway()
            return
        }
        if (this.props.authStatus === PaymentMethodLinkStatus.AUTHORIZED && prevProps.authStatus !== this.props.authStatus) {
            return this.props.syncGateway();
        }


        if (this.props.authStatus === PaymentMethodLinkStatus.REJECTED && prevProps.authStatus !== this.props.authStatus) {
            return this.props.setError('La autorización ha sido rechazada')
        }

        if (this.props.authStatus === PaymentMethodLinkStatus.USED && prevProps.authStatus !== this.props.authStatus) {
            return this.props.setError('El Id de autorización se encuentra en uso')
        }

        if ((this.props.authStatus === PaymentMethodLinkStatus.ERROR && prevProps.authStatus !== this.props.authStatus) ||
            (this.props.authStatus === PaymentMethodLinkStatus.INTERNAL_ERROR && prevProps.authStatus !== this.props.authStatus)) {
            return this.props.setError('Ha ocurrido un error al procesar la autorización. Intente nuevamente!')
        }


        if ((this.props.success && this.props.success !== prevProps.success) ||
            (this.props.delete && this.props.delete !== prevProps.delete)) {
            this.props.getAvailableGateway()
        }

    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleOpen = () => {
        this.setState({
            open: true
        })
    }

    deleteGateway = () => {
        this.props.deleteGateway()
        this.handleClose()
    }
    render() {
        const {
            classes,
            user,
            loading,
            loadingGateway,
            gateway,
            activeAccountGateway,
        } = this.props;
        return (
            <div className={classes.root}>
                <CssBaseline />
                <Container maxWidth={false} className={classes.container}>
                    <AnHeaderBar primary={true} title="perfil" />
                    {/* <Grid container className={classes.cardContainer} >
                        <Card >
                            <CardHeader
                                subheader={user.institucionnombre}
                            />
                            <CardContent>
                                <Box style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>

                                    {(!!user.institucionlogo ?
                                        <Avatar
                                            alt='institucion logo'
                                            className={classes.avatar}
                                            src={user.institucionlogo}
                                        />
                                        :
                                        <CircularProgress
                                            size={40}
                                            color='secondary' />
                                    )}
                                </Box>
                                <div>
                                    <div>
                                        <Typography
                                            gutterBottom
                                            variant="h4"
                                        >
                                            {user.nombre}
                                        </Typography>
                                        <Typography
                                            color="textSecondary"
                                            variant="body1"
                                        >
                                            {`CUIT: 27584439580`}
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid> */}
                    <Grid container>
                        <Grid item xs lg={3}>
                            <Card>
                                {loadingGateway ?
                                    <Grid
                                        container
                                        justify='center'
                                        alignItems='center'
                                        className={classes.cardLoading}
                                    >
                                        <CircularProgress
                                            size={25}
                                            color='secondary' />
                                    </Grid>
                                    : <>
                                        <CardContent>
                                            <Typography
                                                color="textSecondary"
                                                gutterBottom
                                            >
                                                Mercado pago
                                            </Typography>
                                            
                                            <Grid
                                                direction='column'
                                                container
                                                alignContent='center'
                                                alignItems='center'
                                                justify="center">
                                                <Grid item xs>
                                                    <img
                                                        className={classes.media}
                                                        alt="mp"
                                                        src={`/static/images/${gateway !== 0 ? 'mp.png' : 'mp_disabled.png'}`}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography
                                                        color="textSecondary"
                                                        align='center'>
                                                        {gateway !== 0 ? activeAccountGateway : 'No tiene vinculada ninguna cuenta de Mercado Pago'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardActions>
                                            <Grid container justify="center">
                                                {gateway !== 0 ? <Button
                                                    onClick={this.handleOpen}
                                                    color="primary"
                                                    variant="text">
                                                    Desvincular          </Button>
                                                    :
                                                    <Button
                                                        disabled={loading}
                                                        color="primary"
                                                        variant="outlined"
                                                        onClick={() => this.props.startGatewayConfig(1)}
                                                    >

                                                        {loading ?
                                                            <CircularProgress
                                                                color='secondary' size={20} />
                                                            :
                                                            'Vincular'
                                                        }
                                                    </Button>}
                                            </Grid>
                                        </CardActions>
                                    </>
                                }
                            </Card>

                        </Grid>
                    </Grid>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose} >
                        <DialogTitle id="alert-dialog-title">
                            ¿Está seguro de desvincular Mercado Pago?
                                </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Si desvincula Mercado Pago los pagos no se depositarán correctamente          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose}>
                                Cancelar          </Button>
                            <Button onClick={this.deleteGateway} color="primary">
                                Desvincular          </Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            </div >
        );
    }
}

const mapStateToProps = state => ({
    user: state.context.user,
    authStatus: state.gateway.authStatus,
    authRequestId: state.gateway.authRequestId,
    webviewURL: state.gateway.webviewURL,
    webviewTitle: state.gateway.webviewTitle,
    webviewSubtitle: state.gateway.webviewSubtitle,
    loading: state.gateway.loading,
    loadingGateway: state.gateway.loadingGateway,
    gateway: state.gateway.gateway,
    activeAccountGateway: state.gateway.activeAccountGateway,
    delete: state.gateway.delete,
    success: state.gateway.success,
})

const mapDispatchToProps = dispatch => ({
    getAvailableGateway: () => dispatch(GatewayActions.getAvailableGateway()),
    startGatewayConfig: (gateway) => dispatch(GatewayActions.startGatewayConfig(gateway)),
    checkAuthorizationRequestGateway: () => dispatch(GatewayActions.checkAuthorizationRequestGateway()),
    syncGateway: () => dispatch(GatewayActions.syncGateway()),
    deleteGateway: () => dispatch(GatewayActions.deleteGateway()),
    setError: (message) => dispatch(GatewayActions.setError(message))
})


export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen)

