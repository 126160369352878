const defaultState = {
    email: '',
    code: '',
    password: '', //PDACYHBWQqlj23WDlfbN
    emailRestore: null,
    loading: false,
    step: 1,
    error: null,
    userId: '',
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'LOGIN_GET_USER_START': {
            return Object.assign({}, state, {
                loading: true,
                error: null,
            })
        }

        case 'LOGIN_GET_USER_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                userId: action.payload.userId,
                step: 2
            })

        case 'LOGIN_GET_USER_FAILURE':
            return Object.assign({}, state, {
                loading: false,
                error: action.payload.error
            })
        
        case 'LOGIN_AUTHENTICATION_START':
            return Object.assign({}, state, {
                loading: true,
            })

        case 'LOGIN_AUTHENTICATION_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
            })

        case 'LOGIN_AUTHENTICATION_FAILURE':
            return Object.assign({}, state, {
                loading: false,
                error: action.payload.error
            })

        case 'LOGIN_ACTIVATE_USER_START':
            return Object.assign({}, state, {
                loading: true,
                error: null,
                activationSuccess: false,
            })

        case 'LOGIN_ACTIVATE_USER_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                password: '',
                activationSuccess: true,
                step: 4,
            })

        case 'LOGIN_ACTIVATE_USER_FAILURE':
            return Object.assign({}, state, {
                loading: false,
                error: action.payload.error,
            })

        case 'GET_USER_FINISH_ACTIVATION':
            return Object.assign({}, state, {
                loading: false,
                userId: action.payload.userId,
                step: 3
            })
        
        case 'LOGIN_HANDLE_CHANGE':
            return Object.assign({}, state, {
                [action.payload.name]: action.payload.value
            })
        case 'LOGIN_HANDLE_BACK_BTN':
            return Object.assign({}, state, {
                step: action.payload.step,
                error: null,
            })
        default:
            return state;
    }
}
