import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withStyles } from '@material-ui/core/styles';
import {
    useHistory,
    useLocation,
    Link as RouterLink
} from "react-router-dom";

const styles = theme => ({
    root: {
        paddingBottom: 10,
        marginBottom: 10,
        borderBottom: '1px solid #ddd',
    }
});

function AnHeaderBar(props) {
    // Hooks
    let location = useLocation();
    let history = useHistory();

    const { classes,
        primary = false,
        title = 'Profesionales' } = props;


    const pathList = location.pathname.split('/').filter(x => x)

    return (
        <Grid container
            className={classes.root}
            alignItems="center"
            justify="space-between" >
            <Grid item>
                {primary ?
                    <Typography variant="h4" component="h4">
                        {title.toUpperCase()}
                    </Typography>
                    :
                    <IconButton
                        onClick={() => history.goBack()}
                        size="small">
                        <ArrowBackIcon />
                    </IconButton>}
            </Grid>
            <Grid item>
                {!primary &&
                    (<Breadcrumbs aria-label="breadcrumb">
                        {pathList.map((path, index) => {
                            const to = `/${pathList.slice(0, index + 1).join('/')}`;
                            if (path === 'dashboard') return null
                            else if (index + 1 !== pathList.length) {
                                return (
                                    <Link key={index} color="inherit"
                                        component={RouterLink}
                                        to={to} >
                                        {path}</Link>
                                )
                            }
                            else {
                                return (
                                    <Typography key={index} color="textPrimary">{path}</Typography>
                                )
                            }
                        })}

                    </Breadcrumbs>)}
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(AnHeaderBar)