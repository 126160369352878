function setContext(context) {
    return {
        type: 'CONTEXT_SET',
        payload: {
            user: context ? {
                doqid: context.doqid,
                uuid: context.uuid,
                role: context.role,
                usuarionombre: context.usuarionombre,
                usuarioapellido: context.usuarioapellido,
                institucionid: context.institucionid,
                institucionlogo: context.institucionlogo,
                institucionnombre: context.institucionnombre
            } : null
        }
    }
}


export const ContextActions = {
    setContext,
}