import InstitucionRestClient from 'http/resources/InstitucionRestClient';
import ErrorManager, { ApiNonFatalError } from 'services/error-manager';
import firebase from 'firebase/app';
import 'firebase/auth'

function handleBackButton(to) {
    return {
        type: 'LOGIN_HANDLE_BACK_BTN',
        payload: {
            step: to,
        }
    }
}

function handleChangeLogin(name, value) {
    return {
        type: 'LOGIN_HANDLE_CHANGE',
        payload: {
            name,
            value
        }
    }
}


function getUser(email, code) {
    return dispatch => {

        dispatch({
            type: 'LOGIN_GET_USER_START',
        })

        new InstitucionRestClient().getUser({
            email,
            code
        })
            .then(response => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    );
                }
                
                if (response.data.value.pendienteActivacion) {
                    dispatch({
                        type: 'GET_USER_FINISH_ACTIVATION',
                        payload: {
                            userId: response.data.value.usuarioId,
                        }
                    })
                } else {
                    dispatch({
                        type: 'LOGIN_GET_USER_SUCCESS',
                        payload: {
                            userId: response.data.value.usuarioId
                        }
                    })
                }

            })
            .catch(error => {
                dispatch({
                    type: 'LOGIN_GET_USER_FAILURE',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })

    }
}

function loginUser(password) {
    return (dispatch, getState) => {
        dispatch({
            type: 'LOGIN_AUTHENTICATION_START'
        })
        const { userId } = getState().login
        
        new InstitucionRestClient().loginUser({
            userId,
            password,
        })
            .then(response => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    )
                }

                return firebase.auth().signOut()
                    .then(() => firebase.auth().signInWithCustomToken(
                        response.data.value.customToken
                    ))
            })

            .then(() => {
                dispatch({
                    type: 'LOGIN_AUTHENTICATION_SUCCESS'
                })
            })
            .catch(error => {
                dispatch({
                    type: 'LOGIN_AUTHENTICATION_FAILURE',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }
}

function activateUser() {
    return (dispatch, getState) => {
        const {
            userId,
            password,
        } = getState().login
       
        dispatch({
            type: 'LOGIN_ACTIVATE_USER_START',
        })

        new InstitucionRestClient().activateUser({
            userId,
            password,
        })
            .then(response => {
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    );
                }
                
                dispatch({
                    type: 'LOGIN_ACTIVATE_USER_SUCCESS',
                })
            })
            .catch(error => {
                dispatch({
                    type: 'LOGIN_ACTIVATE_USER_FAILURE',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }
}

function logout() {
    return async dispatch => {
        await firebase.auth().signOut()
            .then(() => {
                dispatch({
                    type: 'LOGOUT_IS_SUCCESS'
                })
            })

    }

}


export const LoginActions = {
    getUser,
    loginUser,
    activateUser,
    handleChangeLogin,
    handleBackButton,
    logout,
}