import pattern from 'assets/img/bg-pattern.png';

const styles = theme => ({
    loginBkg: {
        backgroundImage: `url(${pattern})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: theme.palette.primary.main,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    paperIwrap: {
        padding: 15,
    },
    header: {
        padding: '24px 0px 5px',
        textAlign: 'center',
        margin: '0px 5px 0px'
    },
    appImage: {
        width: 72,
        height: 56,
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});


export default styles