import React, { PureComponent } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { ProfessionalsActions } from 'redux/actions'
import ProfessionalList from './components/ProfessionalList';
import { Container, CircularProgress, Box, Typography, Icon } from '@material-ui/core'
import { AnHeaderBar } from 'components/an'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}


class ProfessionalsScreen extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: 0
        }
    }

    componentDidMount() {
        this.props.fetchProfessionals();
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.container}>
                    <AnHeaderBar
                        primary={true}
                        title="profesionales"
                    />

                    {(this.props.loading ? (
                        <Box align="center" p={10}>
                            <CircularProgress size={40} />
                            <Box mt={3}>
                                <Typography>
                                    Cargando ...
                                </Typography>
                            </Box>
                        </Box>
                    ) : this.props.error ? (
                        <Box p={10} align="center" bgcolor="#fff">
                            <Icon style={{ fontSize: 60, color: 'red' }}>
                                report
                            </Icon>
                            
                            <Box mt={2}>
                                <Typography style={{ fontSize: 25, fontWeight: 500, marginBottom: 10, }}>
                                    UPS!
                                </Typography>

                                <Typography>
                                    Ha ocurrido un error al intentar obtener los profesionales viculados
                                </Typography>
                            </Box>
                        </Box>
                    ) : this.props.professionals.length === 0 ? (
                        <Box p={10} align="center" maxWidth={600} padding={3} margin="50px auto" bgcolor="#fff" borderRadius={6} border="1px solid #ddd">
                            <Icon style={{ fontSize: 90, color: '#e7e7e7', marginBottom: 10 }}>
                                people
                            </Icon>
                
                            <Box maxWidth={400}>
                                <Typography style={{ fontWeight: 500, marginBottom: 10, }}>
                                    No hay profesionales vinculados
                                </Typography>

                                <Typography style={{ fontWeight: 400 }}>
                                    Los mismos aparecerán aquí una vez que acepten la solicitud de vinculación
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <Box>
                            <ProfessionalList
                                data={this.props.professionals}
                            />
                        </Box>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    professionals: state.professionals.professionals,
    loading: state.professionals.loading,
    error: state.professionals.error,
})

const mapDispatchToProps = dispatch => ({
    fetchProfessionals: () => dispatch(ProfessionalsActions.fetchProfessionals()),
})


export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalsScreen)

