
export const Countries = [
    { id: 1, name: 'Argentina' },
    { id: 2, name: 'Brasil' },
    { id: 3, name: 'Colombia' },
    { id: 4, name: 'Mexico' },
    { id: 5, name: 'Chile' },
    // { id: 6, name: 'Venezuela' },
    { id: 7, name: 'Perú' },
    { id: 8, name: 'Uruguay' },
    { id: 9, name: 'Panamá' },
    { id: 10, name: 'España' },
    { id: 11, name: 'Ecuador' },
    { id: 12, name: 'Costa Rica' },
    { id: 13, name: 'República Dominicana' },
    { id: 14, name: 'Guatemala' },
    { id: 15, name: 'Honduras' },
    { id: 16, name: 'Paraguay' },
    { id: 17, name: 'Nicaragua' },
    { id: 18, name: 'Bolivia' },
    { id: 41, name: 'El Salvador' },
];

export const Languages = [
    { id: 1, name: 'Español' },
];

export const Titles = [
    { id: 1, name: 'Dr.' },
    { id: 2, name: 'Dra.' },
    { id: 3, name: 'Lic.' },
    { id: 4, name: 'Vet.' },
    { id: 5, name: 'Ps.' },
]

export const TitlesMap = {
    1: 'Dr.',
    2: 'Dra.',
    3: 'Lic.',
    4: 'Vet.',
    5: 'Ps.'
}

export const InvitationState = {
    0: 'Indefinido',
    1: 'Envio Pendiente',
    2: 'Envio con error',
    3: 'Pendiente de Confirmación',
    4: 'Cancelada',
    5: 'Aprobada',
    6: 'Rechazada',
    7: 'Vencida',
}

export const PaymentMethodLinkStatus = {
    UNDEFINED: 0,
    PROCESSING: 1,
    AUTHORIZED: 2,
    REJECTED: 3,
    USED: 4,
    ERROR: 5,
    INTERNAL_ERROR: 'INTERNAL_ERROR',
};

export const TextLabelsTable = {
  body: {
    noMatch: "No se han encontrado registros",
    toolTip: "Ordenar",
    columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
  },
  pagination: {
    next: "Próxima página",
    previous: "Página anterior",
    rowsPerPage: "",
    displayRows: "de",
  },
  toolbar: {
    search: "Buscar",
    downloadCsv: "Descargar CSV",
    print: "Imprimir",
    viewColumns: "Ver columnas",
    filterTable: "Filtrar tabla",
  },
  filter: {
    all: "Todos",
    title: "FILTROS",
    reset: "LIMPIAR",
  },
  viewColumns: {
    title: "Mostrar columnas",
    titleAria: "Mostrar/Ocultar columnas de la tabla",
  },
  selectedRows: {
    text: "fila(s) seleccionadas",
    delete: "Eliminar",
    deleteAria: "Eliminar filas seleccionadas",
  },
};