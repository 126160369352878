import InstitucionRestClient from "http/resources/InstitucionRestClient"
import ErrorManager, { ApiNonFatalError } from 'services/error-manager';
import moment from 'moment';

function searchConsultations(id, from, to, page, pageCount) {
    return dispatch => {
        console.warn('from', from)
        dispatch({
            type: "REPORT_GET_DATA_LOADING",
        })      

        new InstitucionRestClient(true).searchConsultations({
            
            id,
            from: moment(from).startOf('day').local().format(),
            to: moment(to).endOf('day').local().format(),
            page,
            pageCount
        })
            .then(response => {              
                if (response.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(response)
                    );
                }
              
                dispatch({
                    type: 'REPORT_GET_DATA_SUCCESS',
                    payload: {
                        resultado: {
                            isEmpty: response.data.value.resultado.isEmpty,         // bool
                            consultationsList: response.data.value.resultado.items, // array
                            page: response.data.value.resultado.page,               // int
                            pageCount: response.data.value.resultado.pageCount,     // int
                            totalItems: response.data.value.resultado.totalItems,   // int
                        }
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: 'REPORT_GET_DATA_FAILURE',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }
}

function setProfessionalId(id) {
    return async (dispatch) => {
        dispatch({
            type: 'REPORT_SET_PROFESSIONAL',
            payload: {
                professionalId: id
            }
        });
    };
}


function setPage(page) {
    return async (dispatch) => {
        dispatch({
            type: 'REPORT_SET_PAGE',
            payload: {
                page: page
            }
        });
    };
}

function setPageCount(pageCount) {
    return async (dispatch) => {
        dispatch({
            type: 'REPORT_SET_PAGECOUNT',
            payload: {
                pageCount: pageCount
            }
        });
    };
}

export const ReportsActions = {
    searchConsultations,   
    setProfessionalId,
    setPage,
    setPageCount,
}