const defaultState = {
  consultationsList: [],
  isEmpty: true,
  page: 0,
  pageCount: 10,
  totalItems: 0,
  loading: false,
  professionalId: '',
  error: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case "REPORT_GET_DATA_LOADING":
      return Object.assign({}, state, {
        loading: true,
      });

    case "REPORT_GET_DATA_SUCCESS":
      return Object.assign({}, state, {
        loading: false,
        consultationsList: action.payload.resultado.consultationsList,
        isEmpty: action.payload.resultado.isEmpty,       
        page:  action.payload.resultado.page,
        pageCount:  action.payload.resultado.pageCount,
        totalItems:  action.payload.resultado.totalItems,  
      });

    case "REPORT_GET_DATA_FAILURE":
      return Object.assign({}, state, {
        loading: false,
      });

    case "REPORT_SET_PROFESSIONAL":   
      return Object.assign({}, state, {
        professionalId: action.payload.professionalId,       
      });

    case "REPORT_SET_PAGE":   
      return Object.assign({}, state, {
        page: action.payload.page,       
      });

    case "REPORT_SET_PAGECOUNT":   
      return Object.assign({}, state, {
        pageCount: action.payload.pageCount,       
      });

    default:
      return state;
  }
}
