const defaultState = {
    loading: true,
    user: null,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'CONTEXT_SET':
            return Object.assign({}, state, {
                user: action.payload.user,
                loading: false,
            })
        default:
            return state;
    }
}
