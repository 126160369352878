const defaultState = {
    loading: false,
    success: false,
    invitations: [],
    emailInput: '',
    emailList: [],
    validateEmail: [],
    error: null
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'PROFESSIONALS_FETCH_INVITATIONS_START':
            return Object.assign({}, state, {
                loading: true,
            })

        case 'PROFESSIONALS_FETCH_INVITATIONS_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                invitations: action.payload.invitations
            })
        
        case 'PROFESSIONALS_FETCH_INVITATIONS_FAILURE':
            return Object.assign({}, state, {
                loading: false,
            })

        
        case 'INVITATIONS_LOADING':
            return Object.assign({}, state, {
                loading: true
            });
        case 'INVITATIONS_IS_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                success: true,
                emailList: [],
                emailInput: ''
            })
        case 'INVITATIONS_IS_FAILURE':
            return Object.assign({}, state, {
                loading: false,
                error: action.payload.error
            })
        case 'INVITATIONS_HANDLE_INPUT':
            return Object.assign({}, state, {
                [action.payload.name]: action.payload.value,
                emailList: action.payload.emailList,
                success: false,
                validateEmail: action.payload.validateEmail,
            })
        case 'INVITATIONS_CLEAR':
            return Object.assign({}, state, {
                ...defaultState
            })
        default:
            return state;
    }
}
