import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ReportsActions } from "redux/actions";
import MUIDataTable from "mui-datatables";
import { TextLabelsTable } from "../../constants";
import Typography from "@material-ui/core/Typography";
import { format } from 'date-fns'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import moment from 'moment'

class ConsultationList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
      columns: [
        {
          name: "medico",
          label: "Profesional",
          options: {
            filterType: "textField",
            filter: true,
            sort: true,
          },
        },
        {
          name: "paciente",
          label: "Paciente",
          options: {
            filterType: "textField",
            filter: true,
            sort: true,
          },
        },
        {
          name: "nroLinea",
          label: "Nro. de línea",
          options: {
            filterType: "textField",
            filter: true,
            sort: true,
          },
        },
        {
          name: "fechaAbierta",
          label: "Fecha abierta",
          options: {
            filterType: "textField",
            filter: true,
            sort: true,
            customBodyRender: (val) => {
                return (
                    <Typography variant="inherit">
                        {moment.utc(val).local().format('DD/MM/YY hh:mm:ss A')}
                    </Typography>
                )
            },
          },
        },
        {
          name: "fechaCerrada",
          label: "Fecha cerrada",
          options: {
            filterType: "textField",
            filter: true,
            sort: true,
            customBodyRender: (val) => {
                return (
                    <Typography variant="inherit">
                        {moment.utc(val).local().format('DD/MM/YY hh:mm:ss A')}
                    </Typography>
                )
            },
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.setState({
      data: this.getData()
    })
  }

  getData = () => {
    return this.props.data.map((row) => {
      return this.state.columns.map((column) => {
        switch (column.name) {
        //   case "fechaAbierta":
        //   case "fechaCerrada":
        //     return format(new Date(row[column.name]), "dd/MM/yy HH:mm:ss");
          case "nroLinea":
            return parsePhoneNumberFromString('+' + row[column.name]).formatInternational();
          default:
            return row[column.name] || "";
        }
      });
    });
  };

  onChangePage = (page) => {
    this.props.setPage(page)
  };

  onChangeRowsPerPage = (rowsPerPage) => {
    this.props.setPageCount(rowsPerPage)
  };

  render() {
    const { totalItems, page, pageCount } = this.props;

    const options = {
        responsive: "stacked",
      textLabels: TextLabelsTable,
      print: false,
      viewColumns: false,
      filter: false,
      search: false,
      selectableRows: "none",
      serverSide: true,
      pagination: true,
      count: totalItems,
      rowsPerPage: pageCount,
      rowsPerPageOptions: [10, 25, 50, 100],
      page: page,
      onTableChange: (action, tableState) => {
        switch (action) {
          case "changePage":
            this.onChangePage(tableState.page);
            break;
          case "changeRowsPerPage":
            this.onChangeRowsPerPage(tableState.rowsPerPage);
            break;
          default:
            break;
        }
      },
    };

    return (
      <MUIDataTable
        title={
          <div>
            <Typography variant="h6" >Consultas cerradas</Typography>
          </div>
        }
        data={this.state.data}
        columns={this.state.columns}
        options={options}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  professionalId: state.reports.professionalId,
  totalItems: state.reports.totalItems,
  loading: state.reports.loading,
  page: state.reports.page,
  pageCount: state.reports.pageCount,
});

const mapDispatchToProps = (dispatch) => ({
  setPage: (page) => dispatch(ReportsActions.setPage(page)),
  setPageCount: (pageCount) => dispatch(ReportsActions.setPageCount(pageCount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationList);
