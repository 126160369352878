import React from 'react';
import AuthRoute from 'util/AuthRoute';
import { Switch } from "react-router-dom";
import {
    InvitationsScreen,
    ProfessionalsScreen,
    ProfessionalScreen,
    ProfileScreen,
    DirectoryScreen,
    InvitationsFormScreen,
    SpecialityScreen,
    ReportsScreen
} from 'screens';

const DashboardRouting = () => (
    <Switch>
        <AuthRoute exact path='/dashboard/profesionales' component={ProfessionalsScreen} />
        <AuthRoute exact path='/dashboard/invitaciones' component={InvitationsScreen} />
        <AuthRoute path='/dashboard/invitaciones/envio' component={InvitationsFormScreen} />
        <AuthRoute exact path='/dashboard/cartilla' component={DirectoryScreen} />
        <AuthRoute exact path='/dashboard/perfil' component={ProfileScreen} />
        <AuthRoute exact path='/dashboard/profesionales/:code' component={ProfessionalScreen} />
        <AuthRoute path='/dashboard/cartilla/:name' component={SpecialityScreen} />
        <AuthRoute exact path='/dashboard/reportes' component={ReportsScreen} />
    </Switch>
)

export default DashboardRouting